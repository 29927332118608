import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-trim-text',
  templateUrl: './trim-text.component.html',
})
export class TrimTextComponent implements OnInit, OnChanges {
  @Input() text: string = '';
  @Input() limit: number = 19;
  @Input() tooltip: boolean = true;

  trimmedText: string = '';
  tooltipText: string = '';
  trimmed: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.updateText();
  }

  updateText(): void {
    this.trimmed = this.text?.length > this.limit;
    if (this.trimmed) {
      this.trimmedText = this.text.substring(0, this.limit);
    } else {
      this.trimmedText = this.text;
    }
    this.tooltipText = this.text;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateText();
  }
}
