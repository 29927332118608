import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { TranslateService } from '@ngx-translate/core';
import { RootService } from 'app/core';
import { Subscription } from 'rxjs';
import { AppConfig } from '../../../common/classes';

@Component({
  selector: 'afaqy-select-control',
  templateUrl: './afaqy-select-control.component.html',
  styleUrls: ['./afaqy-select-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AfaqySelectControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfaqySelectControlComponent),
      multi: true,
    },
  ],
})
export class AfaqySelectControlComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @ViewChild('sgrid') sgrid: wjcGrid.FlexGrid;
  @ViewChild('dgrid') dgrid: wjcGrid.FlexGrid;
  private _dataList: Array<any> = [];
  private _service: RootService;
  private resourceSubscription: Subscription;

  @Input() set service(value) {
    if (value) {
      this._service = value;
      this.updateSourceCollection();
      this.resourceSubscription?.unsubscribe();
      this.resourceSubscription = this._service.resources.subscribe(() => {
        this.updateSourceCollection();
      });
    }
  }

  @Input() set dataList(value) {
    this.applyResources(value);
    this.pushValue();
    if (value.length > 0) {
      this.isItemsListUpdatable = true;
    }
    this._dataList = value;
  }

  @Input() predefinedList = false;

  @Input() set loading(value) {
    this.isLoading = value;
  }

  @Input() refresh: any;
  @Input() isView: boolean;
  @Input() titleField = 'name';
  @Input() sorted = false;
  @Input() selectedList: any;
  @Input() itemsIDsList: string[] = [];
  @Input() tabName?: string;
  @Input() SearchWithKeys?: string[] = [];
  @Input() useDropDownFilter? = false;
  @Input() unitID?: any; // if unitID is passed from parent component then remove it from list values
  @Input() cid?: any;
  propagateChange: any = () => {};
  validateFn: any = () => {};
  alive: boolean = true;

  @Input() SearchWithItem?: { title: string; value: string }[];
  @Input() SearchWithMultipleValues: boolean = true;
  availableItems: wjcCore.CollectionView;
  selectedItems: wjcCore.CollectionView;
  selectedIds: string[] = [];
  _sourceSearchText: string = '';
  _selectedSearchText: string = '';
  searchKeys: any[] = [];
  isLoading: boolean = false;
  modal: boolean = true;
  itemsList: any[] = [];
  allItemsList: any[] = [];
  isItemsListUpdatable: boolean = false;
  options = [];
  leftSelectedSearchKey: string;
  rightSelectedSearchKey: string;
  leftSearchInputValue: string;
  rightSearchInputValue: string;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    if (this.useDropDownFilter) {
      this.SearchWithKeys.map((key: any) => {
        this.options.push({
          title: this.translate.instant(this.cid + '.' + key),
          value: key,
        });
      });
    }

    if (this.SearchWithKeys?.length) {
      this.leftSelectedSearchKey = this.SearchWithKeys[0];
      this.rightSelectedSearchKey = this.SearchWithKeys[0];
    }
    if (!AppConfig.isCMS) {
      this.updateSourceCollection();
    }

    const tempSelected: any = this.selectedList;
    this.selectedList = [];
    this.itemsList = [];
    if (this.itemsIDsList && this.itemsIDsList.length > 0) {
      this.itemsList = this._dataList.filter((item) =>
        this.itemsIDsList.includes(item.id)
      );
      if (this.itemsIDsList.includes(tempSelected)) {
        this.selectedList = tempSelected;
      }
    }
  }

  addColumns() {
    let items = [];
    this.sgrid.selectedRows.forEach((item) => {
      items.push(item.dataItem);
      this.availableItems.remove(item.dataItem);
    });
    this.selectedItems.sourceCollection =
      this.selectedItems.sourceCollection.concat(items);
    this.selectedIds = this.selectedItems.sourceCollection.map(
      (item: any) => item['id']
    );
    this.pushValue();
  }

  removeColumns() {
    let items = [];
    this.dgrid.selectedRows.forEach((item) => {
      items.push(item.dataItem);
      this.selectedItems.remove(item.dataItem);
    });

    this.availableItems.sourceCollection =
      this.availableItems.sourceCollection.concat(items);
    this.selectedIds = this.selectedItems.sourceCollection.map(
      (item: any) => item['id']
    );
    this.pushValue();
  }

  addAllColumns() {
    this.selectedItems.sourceCollection =
      this.selectedItems.sourceCollection.concat(
        this.availableItems.sourceCollection
      );
    this.selectedIds = this.selectedItems.sourceCollection.map(
      (item: any) => item['id']
    );
    this.availableItems.sourceCollection = [];
    this.pushValue();
  }

  removeAllColumns() {
    this.availableItems.sourceCollection =
      this.availableItems.sourceCollection.concat(
        this.selectedItems.sourceCollection
      );
    this.selectedIds = [];
    this.selectedItems.sourceCollection = [];
    this.pushValue();
  }

  leftDropDownOnSelect(value) {
    this.leftSelectedSearchKey = value;
    this.leftSearchInputValue = null;
    if (this._sourceSearchText.length > 0) {
      this._sourceSearchText = '';
    }
    this.availableItems.refresh();
  }

  rightDropDownOnSelect(value) {
    this.rightSelectedSearchKey = value;
    this.rightSearchInputValue = null;
    if (this._selectedSearchText.length > 0) {
      this._selectedSearchText = '';
    }
    this.selectedItems.refresh();
  }

  // localeTextToLowerCase(text: string): string {
  //   let result = '';
  //   /**
  //    * Unicode character code (uppercase Arabic) -> Unicode character code (lowercase Arabic)
  //    */
  //   const arabicLowerCaseMap = {
  //     1570: 1602, // أ -> ا
  //     1571: 1604, // ب -> ب
  //     1572: 1605, // ت -> ت
  //     1573: 1606, // ث -> ث
  //     1574: 1607, // ج -> ج
  //     1575: 1608, // ح -> ح
  //     1576: 1609, // خ -> خ
  //     1577: 1610, // د -> د
  //     1578: 1611, // ذ -> ذ
  //     1579: 1612, // ر -> ر
  //     1580: 1613, // ز -> ز
  //     1581: 1614, // س -> س
  //     1582: 1615, // ش -> ش
  //     1583: 1616, // ص -> ص
  //     1584: 1617, // ض -> ض
  //     1585: 1618, // ط -> ط
  //     1586: 1619, // ظ -> ظ
  //     1587: 1620, // ع -> ع
  //     1588: 1621, // غ -> غ
  //     1589: 1622, // ف -> ف
  //     1590: 1623, // ق -> ق
  //     1591: 1624, // ك -> ك
  //     1592: 1625, // ل -> ل
  //     1593: 1626, // م -> م
  //     1594: 1627, // ن -> ن (already lowercase)
  //     1595: 1628, // ه -> ه
  //     1596: 1629, // و -> و
  //     1597: 1629, // ي -> ي (already lowercase)

  //     // A-Z equivalents (with diacritics - some examples)
  //     1608: 1640, // حَ -> ح
  //     1610: 1642, // دِ -> د
  //     1613: 1645, // زَ -> ز
  //     1615: 1647, // سِ -> س
  //     1618: 1650, // شَ -> ش

  //     // Additional Arabic characters (e.g., Hamza, Tatweel)
  //     1603: 1603, // ى -> ى (already lowercase)
  //     1619: 1651, // ع (U+0647) -> ع (U+0647)

  //     // You can add more mappings as needed
  //   };

  //   for (let i = 0; i < text.length; i++) {
  //     // Iterate using index
  //     const char = text[i];
  //     const charCode = char.charCodeAt(0);
  //     if (charCode >= 65 && charCode <= 90) {
  //       // A-Z (English uppercase)
  //       result += String.fromCharCode(charCode + 32); // Convert to lowercase
  //     } else if (charCode in arabicLowerCaseMap) {
  //       result += String.fromCharCode(arabicLowerCaseMap[charCode]);
  //     } else {
  //       result += char; // Preserve other characters
  //     }
  //   }
  //   return result;
  // }

  applyFilterForItem(item: any, searchText: any, scope: string) {
    if (typeof searchText !== 'undefined') {
      let result = false;
      if (searchText && searchText.length > 0) {
        searchText = searchText.toLowerCase();
      } else {
        searchText = '';
      }
      let keys = this.searchKeys.map((i) => {
        return i.key;
      });
      let arabic = /[\u0600-\u06FF]/;
      result =
        item[this.titleField] && arabic.test(item[this.titleField])
          ? item[this.titleField].indexOf(searchText) > -1
          : item[this.titleField].toLowerCase().indexOf(searchText) > -1;
      if (scope == 'left') {
        if (this.useDropDownFilter) {
          result =
            item[this.leftSelectedSearchKey]
              .toLowerCase()
              ?.indexOf(searchText) > -1;
        } else {
          if (!this.SearchWithMultipleValues) {
            if (this.SearchWithKeys?.length) {
              result = this.SearchWithKeys.some((key) => {
                return (
                  item[key] && item[key]?.toLowerCase().indexOf(searchText) > -1
                );
              });
            }
          } else {
            const splitSearchText =
              this.validateSearchTextExistInItem(searchText);

            if (splitSearchText.length) {
              result = splitSearchText.some((key) => {
                return (
                  item[this.titleField] &&
                  item[this.titleField]?.toLowerCase().indexOf(key) > -1
                );
              });
            } else {
              result = true;
            }
          }
        }
      } else {
        if (this.useDropDownFilter) {
          result =
            item[this.rightSelectedSearchKey]
              ?.toLowerCase()
              .indexOf(searchText) > -1;
        } else {
          if (!this.SearchWithMultipleValues) {
            if (this.SearchWithKeys?.length) {
              result = this.SearchWithKeys.some((key) => {
                return (
                  item[key] && item[key]?.toLowerCase().indexOf(searchText) > -1
                );
              });
            }
          } else {
            const splitSearchText =
              this.validateSearchTextExistInItem(searchText);

            if (splitSearchText.length) {
              result = splitSearchText.some((key) => {
                return (
                  item[this.titleField] &&
                  item[this.titleField]?.toLowerCase().indexOf(key) > -1
                );
              });
            } else {
              result = true;
            }
          }
        }
      }
      return result;
    } else {
      return true;
    }
  }

  validateSearchTextExistInItem(searchText: string): Array<any> {
    if (searchText && searchText.includes(',')) {
      let searchTextArray = searchText.split(',');
      const filteredSearchTextArray = searchTextArray.filter((str) => {
        if (str !== '' || str !== undefined) {
          return str;
        }
      });
      const filteredArray = filteredSearchTextArray.map((str) => {
        const isFirstCharSpace = str.charAt(0) === ' ';
        if (isFirstCharSpace) {
          str = str.substring(1);
        }
        return str;
      });
      return filteredArray;
    } else {
      return [searchText];
    }
  }

  applyFiltersForAvailable(item: any) {
    const scope = this.SearchWithKeys.length ? 'left' : '';
    return this.applyFilterForItem(item, this._sourceSearchText, scope);
  }

  applyFiltersForSelected(item: any) {
    const scope = this.SearchWithKeys.length ? 'right' : '';

    return this.applyFilterForItem(item, this._selectedSearchText, scope);
  }

  setSourceSearchText(event: any) {
    this._sourceSearchText = event.target.value;
    this.availableItems.refresh();
  }

  setSelectedSearchText(event: any) {
    this._selectedSearchText = event.target.value;
    this.selectedItems.refresh();
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  updateSourceCollection() {
    if (this.predefinedList) {
      if (!this.isItemsListUpdatable) {
        this.allItemsList = this.itemsList = this._dataList;
        this.applyResources(this.allItemsList);
      }
    } else if (this._service) {
      this.isLoading = true;
      if (AppConfig.isCMS) {
        this._service
          .getOptionsList(this._service.routerPrefix(), '', false)
          .subscribe({
            next: (response) => {
              this._dataList = response;
              this.applyResources(response);
              this.isLoading = false;
            },
          });
      } else {
        this._dataList = this._service?.resourcesList || [];
        this.applyResources(this._dataList);
        this.isLoading = false;
      }
    } else {
      this.applyResources(this._dataList);
    }
  }

  applyResources(dataLists: Array<any>) {
    // if (!dataLists?.length) {
    //   dataLists = this.service.resourcesList;
    // }

    // if unitID is passed from parent component then remove it from list values
    if (this.unitID) {
      dataLists = dataLists.filter((item) => item.id != this.unitID);
    }
    let selectedList = [];
    let itemsList = [...dataLists];
    if (!this.sorted) {
      if (this.selectedIds.length) {
        itemsList = itemsList.filter((item) => {
          let index = this.selectedIds.findIndex((elm) => {
            return elm == item['id'];
          });
          if (index >= 0) {
            selectedList.push(item);
          }
          return !(index >= 0);
        });
      }
    } else {
      if (this.selectedIds.length) {
        selectedList = [...this.selectedIds].map((i) => {
          return [...itemsList].find((j) => j.id == i);
        });
        itemsList = [...itemsList].filter(
          (i) => !this.selectedIds.includes(i.id)
        );
      }
    }

    this.availableItems = new wjcCore.CollectionView(itemsList);
    this.availableItems.filter = this.applyFiltersForAvailable.bind(this);
    this.selectedItems = new wjcCore.CollectionView(selectedList);
    this.selectedItems.filter = this.applyFiltersForSelected.bind(this);
  }

  pushValue() {
    this.propagateChange(this.selectedIds);
  }

  writeValue(value: any) {
    if (!value?.length) {
      this.selectedIds = [];
      // this.selectedItems = new wjcCore.CollectionView([]);
      // this.availableItems = new wjcCore.CollectionView([]);
      const availableItems =
        this._dataList && this._dataList?.length ? [...this._dataList] : [];
      this.applyResources(availableItems);
    } else {
      this.selectedIds = value;
      const modelsSelected = this.getModelsBySelectedIds(
        this.selectedIds,
        this._dataList
      );
      const availableModels = this.getModelsNotInSelectedIds(
        this.selectedIds,
        this._dataList
      );
      this.availableItems = new wjcCore.CollectionView(availableModels);
      this.availableItems.filter = this.applyFiltersForAvailable.bind(this);
      this.selectedItems = new wjcCore.CollectionView(modelsSelected);
      this.selectedItems.filter = this.applyFiltersForSelected.bind(this);
    }
  }

  getModelsBySelectedIds(selectedIds: any[], models: any[]): any[] {
    return models.filter((model) => selectedIds.includes(model.id));
  }

  getModelsNotInSelectedIds(selectedIds: any[], models: any[]): any[] {
    return models.filter((model) => !selectedIds.includes(model.id));
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
}
