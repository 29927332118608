<!-- <afaqy-loading [loading]="loading"></afaqy-loading> -->
<afaqy-modal
  [title]="service.cid + '.units-copy-info.title'"
  (modalClose)="modalClose()"
  [hidden]="isModelShow"
  #modal="afaqyModal"
  sizeClass="modal-lg"
>
  <div class="modalbody unit-settings-copy-container">
    <div class="unit-form" *ngIf="item?.id">
      <mat-horizontal-stepper>
        <form [formGroup]="form">
          <!-- start of First Step -->
          <mat-step formGroupName="tabOne">
            <div class="unit-div">
              <p class="unit-item">
                {{ service.cid + '.units-copy-info.source-item' | translate }}
              </p>
              <p class="unit-name">{{ item.name }}</p>
            </div>
            <div class="export-option">
              <perfect-scrollbar>
                <p class="export-title">
                  {{
                    service.cid + '.units-copy-info.export-options' | translate
                  }}
                </p>
                <mat-accordion>
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                    <mat-expansion-panel-header>
                      <div (click)="$event.stopPropagation()">
                        <mat-checkbox
                          class="checkbox"
                          (change)="selectAllOptions($event, 'main')"
                          formControlName="main"
                        >
                          {{
                            service.cid + '.units-copy-info.main' | translate
                          }}
                        </mat-checkbox>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="expansion-details">
                      <ng-container *ngFor="let option of exportOptions?.main">
                        <mat-checkbox
                          [value]="option"
                          [formControlName]="option"
                          (change)="selectSingleOption($event, option, 'main')"
                          >{{
                            service.cid + '.units-copy-info.' + option
                              | translate
                          }}</mat-checkbox
                        >
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion>
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                    <mat-expansion-panel-header>
                      <div (click)="$event.stopPropagation()">
                        <mat-checkbox
                          class="checkbox"
                          (change)="selectAllOptions($event, 'profile')"
                          formControlName="profile"
                        >
                          {{
                            service.cid + '.units-copy-info.profile' | translate
                          }}
                        </mat-checkbox>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="expansion-details">
                      <ng-container
                        *ngFor="let option of exportOptions?.profile"
                      >
                        <mat-checkbox
                          [value]="option"
                          [formControlName]="option"
                          (change)="
                            selectSingleOption($event, option, 'profile')
                          "
                          >{{
                            service.cid + '.units-copy-info.' + option
                              | translate
                          }}</mat-checkbox
                        >
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <ng-container>
                  <div class="un-grouped-div">
                    <mat-checkbox formControlName="groups">{{
                      service.cid + '.units-copy-info.groups' | translate
                    }}</mat-checkbox>
                  </div>
                </ng-container>
                <!-- <ng-container>
                                    <div class="un-grouped-div">
                                        <mat-checkbox formControlName="services">{{service.cid + '.units-copy-info.services'  | translate}}</mat-checkbox>
                                    </div>
                                </ng-container> -->
                <mat-accordion>
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                    <mat-expansion-panel-header>
                      <div (click)="$event.stopPropagation()">
                        <mat-checkbox
                          class="checkbox"
                          (change)="selectAllSensorsOptions($event)"
                          formControlName="sensors"
                        >
                          {{
                            service.cid + '.units-copy-info.sensors' | translate
                          }}
                        </mat-checkbox>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="expansion-details">
                      <ng-container *ngFor="let sensor of item.sensors">
                        <mat-checkbox
                          [value]="sensor.id"
                          [formControlName]="sensor.id"
                          (change)="
                            selectSingleOption($event, sensor.id, 'sensors')
                          "
                          >{{ sensor.name }}</mat-checkbox
                        >
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <ng-container *ngIf="item.device_type !== 'fixed_unit'">
                  <ng-container>
                    <div class="un-grouped-div">
                      <mat-checkbox formControlName="fc">{{
                        service.cid + '.units-copy-info.fuel-consumption'
                          | translate
                      }}</mat-checkbox>
                    </div>
                  </ng-container>
                  <ng-container>
                    <div class="un-grouped-div">
                      <mat-checkbox formControlName="accuracy">{{
                        service.cid + '.units-copy-info.accuracy' | translate
                      }}</mat-checkbox>
                    </div>
                  </ng-container>
                  <!-- Shipment Copy Info -->
                  <ng-container>
                    <div class="un-grouped-div">
                      <mat-checkbox formControlName="shipment">{{
                        service.cid + '.units-copy-info.shipment' | translate
                      }}</mat-checkbox>
                    </div>
                  </ng-container>
                  <!-- End Shipment Copy Info -->

                  <ng-container>
                    <div class="un-grouped-div">
                      <mat-checkbox formControlName="commands">{{
                        service.cid + '.units-copy-info.commands' | translate
                      }}</mat-checkbox>
                    </div>
                  </ng-container>
                  <ng-container>
                    <div class="un-grouped-div">
                      <mat-checkbox formControlName="driver_behavior">{{
                        service.cid + '.units-copy-info.driver-behavior'
                          | translate
                      }}</mat-checkbox>
                    </div>
                  </ng-container>
                  <!-- <ng-container>
                  <div class="un-grouped-div">
                    <mat-checkbox formControlName="custom_fields">{{
                      service.cid + '.units-copy-info.custom' | translate
                    }}</mat-checkbox>
                  </div>
                </ng-container> -->
                  <!-- Custom Fields -->
                  <mat-accordion *ngIf="customFieldsPermission">
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <div (click)="$event.stopPropagation()">
                          <mat-checkbox
                            class="checkbox"
                            (change)="selectAllCustomFieldsOptions($event)"
                            formControlName="custom_fields"
                          >
                            {{
                              service.cid + '.units-copy-info.custom'
                                | translate
                            }}
                          </mat-checkbox>
                        </div>
                      </mat-expansion-panel-header>
                      <div class="expansion-details">
                        <ng-container
                          *ngFor="let customField of item.custom_fields"
                        >
                          <mat-checkbox
                            [value]="customField.id"
                            [formControlName]="customField.id"
                            (change)="
                              selectSingleOption(
                                $event,
                                customField.id,
                                'custom_fields'
                              )
                            "
                            >{{ customField?.key }}</mat-checkbox
                          >
                        </ng-container>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <!-- End Custom Fields -->
                </ng-container>
                <ng-container>
                  <div class="un-grouped-div">
                    <mat-checkbox formControlName="users_list">{{
                      service.cid + '.units-copy-info.access' | translate
                    }}</mat-checkbox>
                  </div>
                </ng-container>
              </perfect-scrollbar>
            </div>
            <div class="step-actions d-flex justify-content-end">
              <div class="action-next">
                <button
                  class="cancel-button"
                  mat-button
                  (click)="modal.closeModal()"
                >
                  {{
                    service.cid + '.units-copy-info.steps-action.cancel'
                      | translate
                  }}
                </button>
                <button
                  class="next-button"
                  mat-button
                  [disabled]="!firstGroupValid"
                  matStepperNext
                >
                  {{
                    service.cid + '.units-copy-info.steps-action.next'
                      | translate
                  }}
                </button>
              </div>
            </div>
          </mat-step>
          <!-- end of first step -->
          <!-- start of second step -->
          <mat-step formGroupName="tabTwo">
            <div class="unit-div">
              <p class="unit-item copy-option-text">
                {{ service.cid + '.units-copy-info.copy-options' | translate }}
              </p>
            </div>
            <div class="copy-options">
              <button
                class="replace-div"
                (click)="setActionTypeValue('replace')"
                [ngClass]="{
                  'action-type-active':
                    form.controls.tabTwo['controls'].action_type.value ===
                    'replace'
                }"
              >
                <div class="div-icon align-self-center">
                  <button type="button" class="btn btn-sm mx-1">
                    <mat-icon
                      class="avl-icon"
                      [svgIcon]="'icon-replace'"
                      [attr.size]="'40px'"
                    ></mat-icon>
                  </button>
                </div>
                <div class="div-description">
                  <p>
                    {{ service.cid + '.units-copy-info.replace' | translate }}
                  </p>
                  <span>{{
                    service.cid + '.units-copy-info.replace-description'
                      | translate
                  }}</span>
                </div>
              </button>
              <!-- <div class="combine-div" (click)="setActionTypeValue('combine')">
                                <div class="div-icon">
                                    <button  type="button" class="btn btn-sm mx-1">
                                        <mat-icon class="avl-icon" [svgIcon]="'icon-combine-info'" [attr.size]="'40px'"></mat-icon>
                                    </button>
                                </div>
                                <div class="div-description">
                                    <p>{{ service.cid + '.units-copy-info.combine' | translate}}</p>
                                    <span>{{ service.cid + '.units-copy-info.combine-description' | translate}}</span>
                                </div>
                            </div> -->
              <button
                class="add-div"
                (click)="setActionTypeValue('add')"
                [ngClass]="{
                  'action-type-active':
                    form.controls.tabTwo['controls'].action_type.value === 'add'
                }"
              >
                <div class="div-icon align-self-center">
                  <button type="button" class="btn btn-sm mx-1">
                    <span
                      class="mdi mdi-plus-circle-outline mdi-36px add-icon"
                    ></span>
                  </button>
                </div>
                <div class="div-description">
                  <p>
                    {{ service.cid + '.units-copy-info.add' | translate }}
                  </p>
                  <span>{{
                    service.cid + '.units-copy-info.add-description' | translate
                  }}</span>
                </div>
              </button>
            </div>
            <div class="step-actions d-flex justify-content-space-between">
              <div>
                <button class="action-previous" mat-button matStepperPrevious>
                  {{
                    service.cid + '.units-copy-info.steps-action.back'
                      | translate
                  }}
                </button>
              </div>
              <div class="action-next">
                <button
                  class="cancel-button"
                  mat-button
                  (click)="modal.closeModal()"
                >
                  {{
                    service.cid + '.units-copy-info.steps-action.cancel'
                      | translate
                  }}
                </button>
                <button
                  class="next-button"
                  mat-button
                  [disabled]="
                    !form.controls.tabTwo['controls'].action_type.value
                  "
                  matStepperNext
                >
                  {{
                    service.cid + '.units-copy-info.steps-action.next'
                      | translate
                  }}
                </button>
              </div>
            </div>
          </mat-step>
          <!-- end of second step -->
          <!-- start of third step -->
          <mat-step formGroupName="tabThree">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="searchType">{{
                  service.cid + '.units-copy-info.filter-by' | translate
                }}</label>
                <select
                  [attr.testid]="'searchType'"
                  id="searchType"
                  class="form-control form-control-sm filter-by"
                  (change)="updateSearchKey($event.target.value)"
                >
                  <option
                    *ngFor="let key of searchKeys"
                    [value]="key.value"
                    [selected]="searchKey == key.value"
                  >
                    {{ 'search.' + key.header | translate }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6 d-flex align-items-end">
                <div class="col-12 input-group form-group-hint filter-by-input">
                  <mat-icon
                    class="avl-icon"
                    [svgIcon]="'icon-search-info'"
                    [attr.size]="'40px'"
                    matPrefix
                  ></mat-icon>
                  <input
                    matInput
                    id="searchKeyword"
                    [attr.testid]="'searchKeyword'"
                    type="text"
                    class="form-control form-control-sm"
                    (keyup)="updateSearchTextInput($event.target.value)"
                    placeholder="{{
                      service.cid + '.units-copy-info.filter-by-placeholder'
                        | translate
                    }}"
                  />
                  <div class="input-group-append hint" matSuffix>
                    <span class="input-group-text">
                      <i
                        class="fa fa-exclamation-circle"
                        afaqy-tooltip
                        [tooltipText]="'search.multiple_search' | translate"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Units list -->
            <div class="units-list-scroll">
              <div class="results-text d-flex align-items-center mb-3">
                <div class="d-flex">
                  <div class="d-flex align-items-center align-items-center">
                    {{ filteredUnits?.length ? filteredUnits?.length : '0' }}

                    {{ service.cid + '.units-copy-info.results' | translate }}
                  </div>

                  <button
                    class="btn btn-outline-primary mx-3"
                    (click)="selectAllFilteredUnits()"
                  >
                    {{
                      service.cid + '.units-copy-info.select-all' | translate
                    }}
                  </button>
                </div>
                <div class="d-flex ml-auto">
                  <button
                    *ngIf="checkedUnitIds?.length"
                    class="btn btn-outline-danger mx-3"
                    (click)="clearSelected()"
                  >
                    {{
                      service.cid + '.units-copy-info.clear-selection'
                        | translate
                    }}
                  </button>

                  <div class="d-flex align-items-center align-items-center">
                    (
                    {{ checkedUnitIds?.length ? checkedUnitIds?.length : '0' }}
                    {{ service.cid + '.units-copy-info.selected' | translate }}
                    /
                    {{
                      service?.resourcesList?.length - 1
                        ? service?.resourcesList?.length - 1
                        : '0'
                    }}
                    {{ service.cid + '.units-copy-info.total' | translate }}
                    )
                  </div>
                </div>
              </div>
              <!-- separate container for checked items start -->
              <!--
                                                        <div class="units-container checked" *ngIf="checkedUnitIds?.length">
                                                            <cdk-virtual-scroll-viewport itemSize="50" class="virtual-viewport" >
                                                                <div *cdkVirtualFor="let row of checkedUnitsRows"  class="virtual-item d-flex w-100" >
                                                                    <div class="d-flex" *ngFor="let id of row" [style.width.%]="100 / unitsColumns">
                                                                        <div class="d-flex" >
                                                                            <mat-checkbox
                                                                                [(ngModel)]="units[id].checked"
                                                                                [checked]="units[id].checked"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                (change)="changedSelectedUnits()"
                                                                            >
                                                                                {{ units[id].name }}
                                                                            </mat-checkbox>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </cdk-virtual-scroll-viewport>
                                                        </div>
                            -->
              <!-- separate container for checked items end -->

              <div class="units-container">
                <cdk-virtual-scroll-viewport
                  itemSize="50"
                  class="virtual-viewport"
                >
                  <div
                    *cdkVirtualFor="let row of unitsRows"
                    class="virtual-item d-flex w-100"
                  >
                    <div
                      class="d-flex"
                      *ngFor="let id of row"
                      [style.width.%]="100 / unitsColumns"
                    >
                      <div class="d-flex">
                        <mat-checkbox
                          [(ngModel)]="units[id].checked"
                          [checked]="units[id].checked"
                          [ngModelOptions]="{
                            standalone: true
                          }"
                          (change)="changedSelectedUnits()"
                        >
                          {{
                            units[id].name.length > 25
                              ? (units[id].name | slice : 0 : 25) + '...'
                              : units[id].name
                          }}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </cdk-virtual-scroll-viewport>
              </div>
            </div>
            <div class="step-actions d-flex justify-content-space-between">
              <div>
                <button class="action-previous" mat-button matStepperPrevious>
                  {{
                    service.cid + '.units-copy-info.steps-action.back'
                      | translate
                  }}
                </button>
              </div>
              <div class="action-next">
                <button
                  class="cancel-button"
                  mat-button
                  (click)="modal.closeModal()"
                >
                  {{
                    service.cid + '.units-copy-info.steps-action.cancel'
                      | translate
                  }}
                </button>
                <button
                  class="next-button"
                  mat-button
                  matStepperNext
                  [disabled]="
                    !form.controls.tabThree['controls'].destination_ids.valid
                  "
                  (click)="onSubmit()"
                >
                  {{
                    service.cid + '.units-copy-info.steps-action.done'
                      | translate
                  }}
                </button>
              </div>
            </div>
          </mat-step>
          <!-- end of third step -->
          <mat-step>
            <div
              class="details-text d-flex align-items-center justify-content-center h-350"
            >
              <mat-icon
                class="avl-icon"
                [svgIcon]="'icon-request-progress-info'"
                [attr.size]="'200px'"
              ></mat-icon>
              <div class="request-text">
                <p>
                  {{
                    service.cid + '.units-copy-info.request-progress'
                      | translate
                  }}
                </p>
                <span>{{
                  service.cid + '.units-copy-info.request-progress-description'
                    | translate
                }}</span>
              </div>
            </div>
            <div class="step-actions d-flex justify-content-end">
              <div class="action-next">
                <button
                  class="next-button"
                  (click)="modal.closeModal()"
                  mat-button
                  matStepperNext
                >
                  {{
                    service.cid + '.units-copy-info.steps-action.done'
                      | translate
                  }}
                </button>
              </div>
            </div>
          </mat-step>
        </form>
      </mat-horizontal-stepper>
    </div>

    <div *ngIf="!item?.id" class="h-100">
      <div
        class="sucess-model"
        *ngIf="service?.copyInfoData?.operation_status === 'completed'"
      >
        <div
          class="details-text d-flex align-items-center justify-content-center h-350"
        >
          <mat-icon
            class="avl-icon"
            [svgIcon]="'icon-unit-updated-success'"
            [attr.size]="'200px'"
          ></mat-icon>
          <div class="request-text">
            <p>
              {{
                service.cid + '.units-copy-info.units-updated-successfully'
                  | translate
              }}
            </p>
            <span>{{
              service.cid + '.units-copy-info.units-updated-description'
                | translate
            }}</span>
          </div>
        </div>
        <div class="step-actions d-flex justify-content-end">
          <div class="action-next p-20">
            <button class="next-button" (click)="closeModal()" mat-button>
              {{
                service.cid + '.units-copy-info.steps-action.done' | translate
              }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="errors-model h-100"
        *ngIf="
          service?.copyInfoData?.operation_status === 'completed_with_failures'
        "
      >
        <div class="units-container-error">
          <div class="errors-table">
            <table mat-table [dataSource]="errorList" class="">
              <!-- unitName Column -->
              <ng-container matColumnDef="unit_name">
                <th mat-header-cell *matHeaderCellDef>Unit Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.unit_name }}
                </td>
              </ng-container>

              <!-- errorType Column -->
              <ng-container matColumnDef="error">
                <th mat-header-cell *matHeaderCellDef>Error Type</th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.error }}
                </td>
              </ng-container>

              <!-- totalErrors Column -->
              <ng-container matColumnDef="totalErrors">
                <th mat-header-cell *matHeaderCellDef>
                  Total Errors : {{ totalErrorsNumber }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.totalErrors }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <div class="step-actions d-flex justify-content-end">
            <div class="action-next pb-20">
              <button class="cancel-button" (click)="closeModal()" mat-button>
                {{
                  service.cid + '.units-copy-info.steps-action.cancel'
                    | translate
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</afaqy-modal>
