import { inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConfig } from 'app/common';
import { AuthService } from 'app/core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authService = inject(AuthService);
    if (req.url.startsWith(AppConfig.baseURL)) {
      // console.log(req)
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          // console.log(error)
          if (
            req.responseType === 'blob' &&
            error.error instanceof Blob &&
            error.error.type === 'application/json'
          ) {
            // Convert Blob error response to JSON
            return new Observable<HttpEvent<any>>((observer) => {
              const reader = new FileReader();
              reader.onload = (e: Event) => {
                const errorText = (e.target as FileReader).result as string;
                const errorJson = JSON.parse(errorText);
                error = new HttpErrorResponse({
                  error: errorJson,
                  headers: error.headers,
                  status: error.status,
                  statusText: error.statusText,
                  url: error.url,
                });
                this.handleError(error, authService);
                observer.error(error);
                observer.complete();
              };
              reader.onerror = (e: Event) => {
                observer.error(error);
                observer.complete();
              };
              reader.readAsText(error.error);
            });
          } else {
            this.handleError(error, authService);
            return throwError(error);
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private handleError(
    error: HttpErrorResponse,
    authService: AuthService
  ): void {
    if (error.status === 423) {
      if (
        error?.error?.message === 'password_expired' &&
        error?.error?.username
      ) {
        authService.passwordExpired.next({
          expired: true,
          username: error?.error?.username,
        });
      }
    } else if (error?.status == 401) {
      if (
        authService?.userType === 'loggedAsUser' ||
        authService.loggedOutSession
      ) {
        authService.logoutLoggedAs();
      } else {
        authService.redirectTOLogin();
      }
    }
  }
}
