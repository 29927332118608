import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';

import { ProtocolCommandService } from 'app/modules/protocol_command/services';
import { Unit, UnitCommand } from 'app/modules/units/models';
import { UnitCommandService, UnitService } from 'app/modules/units/services';
import { AuthService } from '../../../../core/services';
import { Message } from './../../../../common/classes';
import { UnitFormBaseSubComponent } from 'app/shared/components/unitFormSub/unit-form-base-sub.component';

@Component({
  selector: 'unit-form-commands',
  templateUrl: './unit-form-commands.component.html',
  styleUrls: ['./unit-form-commands.component.scss'],
})
export class UnitFormCommandsComponent
  extends UnitFormBaseSubComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() group: UntypedFormGroup;
  @Input() unitObject: Unit;
  @Input() device: string;
  controller = 'unit_commands';
  cid = 'units-unit_commands-';
  selectedItem: UnitCommand;
  moduleKey = 'commands';

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public service: UnitCommandService,
    public unitService: UnitService,
    private protocolCommandService: ProtocolCommandService
  ) {
    super(route, authService, service, unitService);
    this.protocolCommandService.startAutoload();
  }

  ngOnInit() {
    super.ngOnInit();
    this.protocolCommandService.resources.subscribe({
      next: () => {
        this.loadItems();
      },
    });
  }

  loadItems() {
    this.itemsList.sourceCollection = this.unitObject?.[this.moduleKey]?.map(
      (command) => {
        if (command.protocol_command_id) {
          command.type = this.protocolCommandService.getResourceTitle(
            command.protocol_command_id
          );
        } else {
          command.type = 'custom';
        }

        return command;
      }
    );
  }

  update(event) {
    super.update(event);
    this.loadItems();
    this.selectedItem = new UnitCommand();
  }

  addForm() {
    super.addForm();
    this.selectedItem = new UnitCommand();
    this.openForm();
  }
}
