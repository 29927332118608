import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { filter, startWith, takeWhile } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AfaqyHelper, Message } from './../../../../common/classes';
import { AfaqyResponse } from './../../../../core/classes';
import { UnitCommand } from 'app/modules/units/models';
import { UnitCommandService, UnitService } from 'app/modules/units/services';
import { ProtocolCommandService } from 'app/modules/protocol_command/services';

@Component({
  selector: 'unit-commands-form',
  templateUrl: './unit-form-commands-form.component.html',
})
export class UnitFormCommandsFormComponent
  implements OnChanges, OnInit, OnDestroy
{
  alive: boolean = true;
  @Input() object: UnitCommand;
  @Input() unitID: string;

  @Input() unitDevice: string;
  @Input() isView;
  @Input() isCopy;

  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateList: EventEmitter<any> = new EventEmitter<any>();

  iconsList = [];
  title: string = '';
  form: UntypedFormGroup;
  message: Message;
  cid = 'units-unit_commands-';

  posting = false;
  loading = false;
  forceDeactivate: boolean = false;

  hideParam = true;
  channelsValue: any;
  formOptions = {
    protocolCommandsList: [],
  };

  constructor(
    public translate: TranslateService,
    protected fb: UntypedFormBuilder,
    public service: UnitCommandService,
    public unitService: UnitService,
    public protocolCommandsService: ProtocolCommandService
  ) {
    this.object = new UnitCommand();
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillFormValues();
  }

  get form_fields(): any {
    return {
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(100),
        ],
      ],
      protocol_command_id: ['', [Validators.required]],
      param: ['', []],
      channel: ['', [Validators.required]],
      custom: [false, []],
    };
  }

  ngOnInit() {
    this.message = new Message();
    this.protocolCommandsService
      .protocolCommandsList(this.unitDevice)
      .subscribe({
        next: (list) => {
          this.formOptions.protocolCommandsList = list;
          this.channelsValue = [...this.service.channelsValue];
          this.getProtocolCommandIdValue();
          this.prepareProtocoleCommand();
        },
      });

    this.fillFormValues();
    this.formInit();
  }

  getProtocolCommandIdValue() {
    const commandId = this.form.get('protocol_command_id').value;
    if (commandId) {
      this.updateChannelLists(commandId);
    }
  }

  updateChannelLists(commandId: any) {
    const selectedCommand = this.formOptions.protocolCommandsList.find(
      (command) => command.id === commandId
    );
    if (selectedCommand && selectedCommand.id !== 'custom') {
      if (selectedCommand.channel) {
        if (
          selectedCommand.channel === 'iridium' &&
          !this.channelsValue.some((channel) => channel.k === 'iridium')
        ) {
          this.channelsValue = [
            ...this.channelsValue,
            { k: 'iridium', v: 'Iridium' },
          ];
        }
        this.form.get('channel').setValue(selectedCommand.channel);
        this.form.get('channel').updateValueAndValidity();
        this.form.get('channel').disable();
      } else {
        this.form.get('channel').enable();
        this.form.get('channel').setValue(selectedCommand.channel);
      }
    } else if (
      selectedCommand &&
      selectedCommand.id === 'custom' &&
      this.unitDevice === 'galileo'
    ) {
      this.form.get('channel').enable();
      if (!this.channelsValue.some((channel) => channel.k === 'iridium')) {
        this.channelsValue = [
          ...this.channelsValue,
          { k: 'iridium', v: 'Iridium' },
        ];
      }
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  applySideEffects() {
    if (this.object.id || this.isCopy) {
      if (this.object.custom) {
        this.form.controls['protocol_command_id'].setValue('custom');
      }
    }
  }

  formInit() {
    this.form.controls['protocol_command_id'].valueChanges.subscribe({
      next: (commandId) => {
        this.prepareProtocoleCommand();
        this.updateChannelLists(commandId);
      },
    });
  }

  prepareProtocoleCommand() {
    const oneCommand = this.formOptions.protocolCommandsList.find(
      (x) => x.id === this.form.get('protocol_command_id').value
    );
    if (oneCommand) {
      this.form.controls['param'].setValidators(
        oneCommand.param_option == 'required' ? [Validators.required] : null
      );
      this.hideParam = oneCommand.param_option == 'hidden';
      this.form.controls['param'].setValue(oneCommand.param);
      this.form.controls['channel'].setValue(oneCommand.channel);
      if (this.hideParam) {
        this.form.controls['param'].setValue('');
      }
      if (oneCommand.id === 'custom') this.form.controls['channel'].enable();
    } else {
      this.hideParam = true;
    }
  }

  isEditUnit() {
    return this.unitID;
  }

  canDeactivate() {
    if (this.forceDeactivate || this.form.pristine) {
      return true;
    }
    return this.service.confirm();
  }

  fillFormValues() {
    let fobj = {};
    if (this.object) {
      for (let field in this.form_fields) {
        fobj[field] = this.object[field];
      }
    }
    this.form.reset(fobj);
    this.applySideEffects();
  }

  createForm() {
    this.form = this.fb.group(this.form_fields);
    this.applySideEffects();
    this.forceDeactivate = false;
  }

  reset() {
    this.fillFormValues();
    if (!this.object.id) {
      this.prepareProtocoleCommand();
      if (this.isCopy) {
        this.form.get('channel').setValue(this.object.channel);
      } else {
        this.channelsValue = this.channelsValue.filter(
          (channel) => channel.k !== 'iridium'
        );
        this.form.controls['channel'].patchValue(null);
        this.form.get('channel').enable();
        this.form.get('channel').updateValueAndValidity();
      }
    } else {
      this.form.get('channel').setValue(this.object.channel);
    }
    this.form.get('param').setValue(this.object.param);
  }

  modalClose($event) {
    this.closeForm.next($event);
  }

  prepareSave(): UnitCommand {
    const formModel = this.form.getRawValue();
    const saveObj = this.service.modelInstance;
    saveObj.channel = formModel.channel;

    saveObj.id = this.object.id;
    for (let field in this.form_fields) {
      saveObj[field] = formModel[field] as string;
    }
    saveObj.unit_id = this.unitID;
    if (saveObj.protocol_command_id == 'custom') {
      saveObj.custom = true;
      saveObj.protocol_command_id = '';
    } else {
      saveObj.custom = false;
    }
    return saveObj;
  }

  afterFail(err) {
    this.posting = false;
    let error = new AfaqyResponse();
    error.copyInto(JSON.parse(err));
    let errorsList = error.errors;
    AfaqyHelper.setFGErrors(this.form, errorsList);
    this.message.type = 'danger';
    this.message.message = error.message || 'please-try-again';
    AfaqyHelper.calcModalHeight(true);
  }

  afterSuccess(msg = {}) {
    AfaqyHelper.calcModalHeight(false);
    this.posting = false;
    this.forceDeactivate = true;
    this.modalClose({ msg: msg, type: 'success', success: true });
  }

  updateUnitCommandsList() {
    let command = this.object;
    let unit = this.unitService.getUnitDetails(this.object.unit_id);
    let unitCommandsList = unit.commands;
    let updatedCommandsList = unitCommandsList.filter(
      (c) => c.id != command.id
    );
    updatedCommandsList.push(command);
    unit.commands = updatedCommandsList;
    this.unitService.setResourceObject(unit);
  }

  saveToDB() {
    this.object = this.prepareSave();
    this.posting = true;
    this.message.clear();
    if (this.object.id) {
      this.service
        .update(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: () => {
            this.updateList.next({ object: this.object });
            this.updateUnitCommandsList();
            this.afterSuccess('notifications.unit_commands.updated');
          },
          error: (error) => {
            this.afterFail(error);
          },
        });
    } else {
      this.service
        .create(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (response: AfaqyResponse) => {
            this.object.id = response.data.id;
            this.updateList.next({ object: this.object });
            this.updateUnitCommandsList();
            this.afterSuccess('notifications.unit_commands.added');
          },
          error: (error) => {
            this.afterFail(error);
          },
        });
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      AfaqyHelper.touchAll(this.form);
      return;
    }
    if (this.isEditUnit()) {
      this.saveToDB();
    } else {
      this.object = this.prepareSave();
      this.updateList.next({ object: this.object });
    }
  }
}
