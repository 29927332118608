export class SensorType {
  public static formOptions = {
    types: [
      {
        id: 'acc',
        nameEn: 'Ignition ACC',
        nameAr: 'حساس المحرك',
        measurement_Units: [],
      },
      {
        id: 'di',
        nameEn: 'Digital Input',
        nameAr: 'المدخلات الرقمية',
        measurement_Units: [],
      },
      {
        id: 'ai',
        nameEn: 'Analog Input',
        nameAr: 'مدخلات تناظرية',
        measurement_Units: [],
      },
      {
        id: 'temperature',
        nameEn: 'Temperature',
        nameAr: 'الحرارة',
        defaultMeasurementUnit: '°C',
        measurement_Units: [
          {
            id: '°C',
            nameEn: '°C',
            nameAr: '°C',
          },
          {
            id: '°F',
            nameEn: '°F',
            nameAr: '°F',
          },
        ],
      },
      {
        id: 'humidity',
        nameEn: 'Humidity',
        nameAr: 'الرطوبة',
        defaultMeasurementUnit: '%',
        measurement_Units: [
          {
            id: '%',
            nameEn: '%',
            nameAr: '%',
          },
        ],
      },
      {
        id: 'door',
        nameEn: 'Door',
        nameAr: 'الباب',
        measurement_Units: [],
      },
      {
        id: 'door2',
        nameEn: 'Door 2',
        nameAr: 'الباب 2',
        measurement_Units: [],
      },
      {
        id: 'CU',
        nameEn: 'Cool Unit',
        nameAr: 'وحدة التبريد',
        measurement_Units: [],
      },
      {
        id: 'rfid',
        nameEn: 'RFID',
        nameAr: 'rfid',
        measurement_Units: [],
      },
      {
        id: 'rpm',
        nameEn: 'RPM',
        nameAr: 'دورة في الدقيقة',
        measurement_Units: [],
      },
      {
        id: 'uhfid',
        nameEn: 'UHFID',
        nameAr: 'UHFID',
        measurement_Units: [],
      },
      {
        id: 'weight',
        nameEn: 'Weight',
        nameAr: 'الوزن',
        measurement_Units: [],
      },
      {
        id: 'liquid',
        nameEn: 'Liquid',
        nameAr: 'سائل',
        measurement_Units: [],
      },
      {
        id: 'fuel',
        nameEn: 'Fuel',
        nameAr: 'وقود',
        measurement_Units: [],
      },
      {
        id: 'fuel_per',
        nameEn: 'Fuel Percentage',
        nameAr: 'نسبة الوقود',
        measurement_Units: [],
      },
      {
        id: 'custom',
        nameEn: 'Custom',
        nameAr: 'حساس عام',
        measurement_Units: [],
      },
      {
        id: 'eng_hour',
        nameEn: 'Engine Hours',
        nameAr: 'عدد ساعات تشغيل المحرك',
        measurement_Units: [],
      },
      {
        id: 'milage',
        nameEn: 'Engine Mileage',
        nameAr: 'عداد الكيلومتر',
        measurement_Units: [],
      },
      {
        id: 'fc',
        nameEn: 'Fuel Consumption',
        nameAr: 'الوقود المستهلك',
        measurement_Units: [],
      },
      {
        id: 'tailer_bind',
        nameEn: 'Trailer Bind',
        nameAr: 'ربط المقطورة',
        measurement_Units: [],
      },
      {
        id: 'driver_bind',
        nameEn: 'Driver Bind',
        nameAr: 'ربط سائق',
        measurement_Units: [],
      },
      {
        id: 'in_battery_volt',
        nameEn: 'Internal Battery Volt',
        nameAr: 'بطاريه داخلية',
        measurement_Units: [],
      },
      {
        id: 'ex_battery_volt',
        nameEn: 'External Battery Volt',
        nameAr: 'بطارية خارجية',
        measurement_Units: [],
      },
      {
        id: 'passengers',
        nameEn: 'Passengers',
        nameAr: 'الركاب',
        measurement_Units: [],
      },
      {
        id: 'sos',
        nameEn: 'SOS',
        nameAr: 'الاستغاثة',
        measurement_Units: [],
      },
      {
        id: 'sos_passenger',
        nameEn: 'SOS Passenger',
        nameAr: 'استغاثة راكب',
        measurement_Units: [],
      },
      {
        id: 'accident',
        nameEn: 'Accident',
        nameAr: 'الحادث',
        measurement_Units: [],
      },
      {
        id: 'gprs',
        nameEn: 'GPRS',
        nameAr: 'gprs',
        measurement_Units: [],
      },
      {
        id: 'sms',
        nameEn: 'SMS',
        nameAr: 'رسالة نصية',
        measurement_Units: [],
      },
      {
        id: 'pto1',
        nameEn: 'PTO 1',
        nameAr: 'PTO 1',
        measurement_Units: [],
      },
      {
        id: 'pto2',
        nameEn: 'PTO 2',
        nameAr: 'PTO 2',
        measurement_Units: [],
      },
      {
        id: 'vehicle_speed_sensor',
        nameEn: 'Vehicle speed sensor',
        nameAr: 'سرعة السيارة',
        measurement_Units: [],
      },
      {
        id: 'harsh_acceleration_sensor',
        nameEn: 'Harsh Acceleration Sensor',
        nameAr: 'التسارع المفاجئ',
        measurement_Units: [],
      },
      {
        id: 'harsh_break',
        nameEn: 'Harsh Break',
        nameAr: 'الكبح المفاجئ',
        measurement_Units: [],
      },
      {
        id: 'harsh_cornering',
        nameEn: 'Harsh Cornering',
        nameAr: 'الانعطاف المفاجئ',
        measurement_Units: [],
      },
      {
        id: 'panic_button_sensor',
        nameEn: 'Panic Button Sensor',
        nameAr: 'زر الاستغاثه',
        measurement_Units: [],
      },
      {
        id: 'seat_belt',
        nameEn: 'Seat Belt',
        nameAr: 'حزام',
        measurement_Units: [],
      },
      {
        id: 'fl_ext',
        nameEn: 'FL Ext',
        nameAr: 'حساس مستوى وقود خارجى',
        measurement_Units: [],
      },
      {
        id: 'fl_can',
        nameEn: 'FL Can',
        nameAr: 'حساس مستوى وقود كان',
        measurement_Units: [],
      },
      {
        id: 'fc_can',
        nameEn: 'FC Can',
        nameAr: 'استهلاك وقود كان',
        measurement_Units: [],
      },
      {
        id: 'total_fuel_can',
        nameEn: 'Total Fuel Can',
        nameAr: 'وقود كلي كان',
        measurement_Units: [],
      },
      {
        id: 'overspeed',
        nameEn: 'Overspeed',
        nameAr: 'تجاوز سرعه',
        measurement_Units: [],
      },
      {
        id: 'excessive_overspeed',
        nameEn: 'Excessive Overspeed',
        nameAr: 'تجاوز سرعه مفرط',
        measurement_Units: [],
      },
      {
        id: 'tampering',
        nameEn: 'Tampering',
        nameAr: 'تلاعب',
        measurement_Units: [],
      },
      {
        id: 'engine_temperature',
        nameEn: 'Engine Temperature',
        nameAr: 'درجه حراره المحرك',
        measurement_Units: [],
      },
      {
        id: 'fuel_level',
        nameEn: 'Fuel Level',
        nameAr: 'مستوى الوقود',
        measurement_Units: [],
      },
      {
        id: 'arm_movement',
        nameEn: 'Arm Movement',
        nameAr: 'حركة الذراع',
        measurement_Units: [],
      },
      {
        id: 'arm_angle',
        nameEn: 'Arm Angle',
        nameAr: 'زاويه الذراع',
        measurement_Units: [],
      },
      {
        id: 'speedlimit',
        nameEn: 'Speed Limit',
        nameAr: 'الحد الأقصى للسرعة',
        measurement_Units: [],
      },
      {
        id: 'night_driving',
        nameEn: 'Night Driving',
        nameAr: 'القيادة الليلية',
        measurement_Units: [],
      },
      {
        id: 'rest_violation',
        nameEn: 'Rest Violation',
        nameAr: 'مخالفة الراحة',
        measurement_Units: [],
      },
      {
        id: 'driving_distance_violation',
        nameEn: 'Driving Distance Violation',
        nameAr: 'مخالفه مسافة القيادة',
        measurement_Units: [],
      },
      {
        id: 'air_quality_co2',
        nameEn: 'CO2',
        nameAr: 'ثانى أكسيد الكربون',
        defaultMeasurementUnit: 'mg/m³',
        measurement_Units: [
          {
            id: 'mg/m³',
            nameEn: 'mg/m³',
            nameAr: 'مللى جرام/متر مكعب',
          },
          {
            id: 'g/km',
            nameEn: 'g/km',
            nameAr: 'جرام/كيلومتر',
          },
        ],
      },
      {
        id: 'no2',
        nameEn: 'NO2',
        nameAr: 'أكسيد النيتروجين',
        defaultMeasurementUnit: 'mg/m³',
        measurement_Units: [
          {
            id: 'mg/m³',
            nameEn: 'mg/m³',
            nameAr: 'مللى جرام/متر مكعب',
          },
          {
            id: 'g/km',
            nameEn: 'g/km',
            nameAr: 'جرام/كيلومتر',
          },
        ],
      },
      {
        id: 'co',
        nameEn: 'CO',
        nameAr: 'أول أكسيد الكربون',
        defaultMeasurementUnit: 'mg/m³',
        measurement_Units: [
          {
            id: 'mg/m³',
            nameEn: 'mg/m³',
            nameAr: 'مللى جرام/متر مكعب',
          },
          {
            id: 'g/km',
            nameEn: 'g/km',
            nameAr: 'جرام/كيلومتر',
          },
        ],
      },
      {
        id: 'so2',
        nameEn: 'SO2',
        nameAr: 'ثاني أكسيد الكبريت',
        defaultMeasurementUnit: 'mg/m³',
        measurement_Units: [
          {
            id: 'mg/m³',
            nameEn: 'mg/m³',
            nameAr: 'مللى جرام/متر مكعب',
          },
          {
            id: 'g/km',
            nameEn: 'g/km',
            nameAr: 'جرام/كيلومتر',
          },
        ],
      },
      {
        id: 'tpm',
        nameEn: 'TPM',
        nameAr: 'الجسيمات الكلية',
        defaultMeasurementUnit: 'mg/m³',
        measurement_Units: [
          {
            id: 'mg/m³',
            nameEn: 'mg/m³',
            nameAr: 'مللى جرام/متر مكعب',
          },
          {
            id: 'g/km',
            nameEn: 'g/km',
            nameAr: 'جرام/كيلومتر',
          },
        ],
      },
      {
        id: 'o2',
        nameEn: 'O2',
        nameAr: 'الأكسجين',
        defaultMeasurementUnit: '%',
        measurement_Units: [
          {
            id: '%',
            nameEn: '%',
            nameAr: '%',
          },
        ],
      },
      {
        id: 'rssi',
        nameEn: 'RSSI',
        nameAr: 'قوه الاشاره',
        defaultMeasurementUnit: 'dBm',
        measurement_Units: [
          {
            id: 'dBm',
            nameEn: 'dBm',
            nameAr: 'dBm',
          },
        ],
      },
    ],
    result_types: [
      'logic',
      'logicgte',
      'string',
      'value',
      'percentage',
      'codeValue',
      'hexString',
    ],
    formulas: ['+', '-', '/', '*'],
    params: ['parm253'],
  };
}

export class zoneCondition {
  public static formOptions = {
    zone_condition: ['zone_in', 'zone_out'],
  };
}
