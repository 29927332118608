import { AppModel, AfaqyObject } from '../../../common/classes';
import { EventsSettings } from './../services/events-settings';
import { TranslateService } from '@ngx-translate/core';

class Notify extends AfaqyObject {
  private _email: boolean;
  private _emails: string[];
  private _sms: boolean;
  private _sms_numbers: string[];
  private _mobile: boolean;
  private _system: boolean;
  private _system_sound: string;
  private _notify_every: string;
  private _violation_penalty: string;

  protected copyFields = [
    'email',
    'emails',
    'sms',
    'sms_numbers',
    'mobile',
    'system',
    'system_sound',
    'notify_every',
    'violation_penalty',
  ];
  protected createFields = [
    'email',
    'emails',
    'sms',
    'sms_numbers',
    'mobile',
    'system',
    'system_sound',
    'notify_every',
    'violation_penalty',
  ];
  protected updateFields = [
    'email',
    'emails',
    'sms',
    'sms_numbers',
    'mobile',
    'system',
    'system_sound',
    'notify_every',
    'violation_penalty',
  ];

  get email(): boolean {
    return this._email;
  }

  set email(value: boolean) {
    this._email = value;
  }

  get emails(): string[] {
    return this._emails;
  }

  set emails(value: string[]) {
    // let data = [];
    // for(let k in value) {
    //     data.push(value[k]['value']);
    // }
    // this._emails = data;
    this._emails = value;
  }

  get sms(): boolean {
    return this._sms;
  }

  set sms(value: boolean) {
    this._sms = value;
  }

  get sms_numbers(): string[] {
    return this._sms_numbers;
  }

  set sms_numbers(value: string[]) {
    // let data = [];
    // for(let k in value) {
    //     data.push(value[k]['value']);
    // }
    // this._sms_numbers = data;
    this._sms_numbers = value;
  }

  get mobile(): boolean {
    return this._mobile;
  }

  set mobile(value: boolean) {
    this._mobile = value;
  }

  get system(): boolean {
    return this._system;
  }

  set system(value: boolean) {
    this._system = value;
  }

  get system_sound(): string {
    return this._system_sound;
  }

  set system_sound(value: string) {
    this._system_sound = value;
  }

  get notify_every(): string {
    return this._notify_every;
  }

  set notify_every(value: string) {
    this._notify_every = value;
  }

  get violation_penalty(): string {
    return this._violation_penalty;
  }

  set violation_penalty(value: string) {
    this._violation_penalty = value;
  }
}

class Action extends AfaqyObject {
  private _send_cmd: boolean;
  private _command: string;
  private _gateway: string;
  private _type: string;
  private _callback_url: string;
  private _unit_status: string;

  protected copyFields = [
    'send_cmd',
    'command',
    'gateway',
    'type',
    'callback_url',
    'unit_status',
  ];
  protected createFields = [
    'send_cmd',
    'command',
    'gateway',
    'type',
    'callback_url',
    'unit_status',
  ];
  protected updateFields = [
    'send_cmd',
    'command',
    'gateway',
    'type',
    'callback_url',
    'unit_status',
  ];

  get send_cmd(): boolean {
    return this._send_cmd;
  }

  set send_cmd(value: boolean) {
    this._send_cmd = value;
  }

  get command(): string {
    return this._command;
  }

  set command(value: string) {
    this._command = value;
  }

  get gateway(): string {
    return this._gateway;
  }

  set gateway(value: string) {
    this._gateway = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get callback_url(): string {
    return this._callback_url;
  }

  set callback_url(value: string) {
    this._callback_url = value;
  }

  get unit_status(): string {
    return this._unit_status;
  }

  set unit_status(value: string) {
    this._unit_status = value;
  }
}

class Limit extends AfaqyObject {
  private _days: DaysLimit = new DaysLimit();
  private _times: TimeLimit[] = [];
  private _days_time_limit: boolean;

  protected copyFields = ['days', 'days_time_limit', 'times'];
  protected createFields = ['days', 'days_time_limit', 'times'];
  protected updateFields = ['days', 'days_time_limit', 'times'];

  get days(): DaysLimit {
    return this._days;
  }

  set days(value: DaysLimit) {
    this._days = value;
  }

  get days_time_limit(): boolean {
    return this._days_time_limit;
  }

  set days_time_limit(value: boolean) {
    this._days_time_limit = value;
  }

  get times(): TimeLimit[] {
    return this._times;
  }

  set times(value: TimeLimit[]) {
    this._times = value;
  }
}

// class EventTimes extends AfaqyObject {
//     private _prd1: TimeLimit = new TimeLimit;
//     private _prd2: TimeLimit = new TimeLimit;
//     protected copyFields = ['prd1', 'prd2'];
//
//     get prd1(): TimeLimit {
//         return this._prd1;
//     }
//
//     set prd1(value: TimeLimit) {
//         this._prd1 = value;
//     }
//
//     get prd2(): TimeLimit {
//         return this._prd2;
//     }
//
//     set prd2(value: TimeLimit) {
//         this._prd2 = value;
//     }
// }
class TimeLimit extends AfaqyObject {
  private _from: any;
  private _to: any;
  protected copyFields = ['from', 'to'];
  protected createFields = ['from', 'to'];
  protected updateFields = ['from', 'to'];

  get from(): any {
    return this._from ? this._from : '10:20';
  }

  set from(value: any) {
    this._from = value;
  }

  get to(): any {
    return this._to ? this._to : '00:00';
  }

  set to(value: any) {
    this._to = value;
  }
}
class DaysLimit extends AfaqyObject {
  private _sa: boolean;
  private _su: boolean;
  private _mo: boolean;
  private _tu: boolean;
  private _we: boolean;
  private _th: boolean;
  private _fr: boolean;
  protected copyFields = ['sa', 'su', 'mo', 'tu', 'we', 'th', 'fr'];

  get sa(): boolean {
    return typeof this._sa === 'boolean' ? this._sa : true;
  }

  set sa(value: boolean) {
    this._sa = value;
  }

  get su(): boolean {
    return typeof this._su === 'boolean' ? this._su : true;
  }

  set su(value: boolean) {
    this._su = value;
  }

  get mo(): boolean {
    return typeof this._mo === 'boolean' ? this._mo : true;
  }

  set mo(value: boolean) {
    this._mo = value;
  }

  get tu(): boolean {
    return typeof this._tu === 'boolean' ? this._tu : true;
  }

  set tu(value: boolean) {
    this._tu = value;
  }

  get we(): boolean {
    return typeof this._we === 'boolean' ? this._we : true;
  }

  set we(value: boolean) {
    this._we = value;
  }

  get th(): boolean {
    return typeof this._th === 'boolean' ? this._th : true;
  }

  set th(value: boolean) {
    this._th = value;
  }

  get fr(): boolean {
    return typeof this._fr === 'boolean' ? this._fr : true;
  }

  set fr(value: boolean) {
    this._fr = value;
  }
}

class SensorConditions extends AfaqyObject {
  private _sensor: string;
  private _op: string;
  private _val: number;

  protected copyFields = ['sensor', 'op', 'val'];
  protected createFields = ['sensor', 'op', 'val'];
  protected updateFields = ['sensor', 'op', 'val'];

  get sensor(): string {
    return this._sensor;
  }

  set sensor(value: string) {
    this._sensor = value;
  }

  get op(): string {
    return this._op;
  }

  set op(value: string) {
    this._op = value;
  }

  get val(): number {
    return this._val;
  }

  set val(value: number) {
    this._val = value;
  }
}

class EventConditions extends AfaqyObject {
  private _sensor: string;
  private _service_id: string;
  private _time_minutes: any;
  private _no_conn_period_min: number;
  private _speed_limit: number;
  private _volume: number;
  private _speed_diff: number;
  private _angle_diff: number;
  private _min_speed: number;
  private _sensor_conditions: SensorConditions[];
  private _zone_groups: string[];
  private _zones: string[];
  private _customer_types: string[];
  private _customers: string[];
  private _detection: string;
  private _apply_zone_condition: boolean;
  private _zone_condition: string[];

  protected copyFields = [
    'sensor',
    'time_minutes',
    'no_conn_period_min',
    'speed_limit',
    'speed_diff',
    'angle_diff',
    'min_speed',
    'sensor_conditions',
    'zone_groups',
    'zones',
    'customer_types',
    'customers',
    'service_id',
    'detection',
    'apply_zone_condition',
    'zone_condition',
    'volume',
  ];
  protected createFields = [
    'sensor',
    'time_minutes',
    'no_conn_period_min',
    'speed_limit',
    'speed_diff',
    'angle_diff',
    'min_speed',
    'sensor_conditions',
    'zone_groups',
    'zones',
    'customer_types',
    'customers',
    'service_id',
    'detection',
    'apply_zone_condition',
    'zone_condition',
    'volume',
  ];
  protected updateFields = [
    'sensor',
    'time_minutes',
    'no_conn_period_min',
    'speed_limit',
    'speed_diff',
    'angle_diff',
    'min_speed',
    'sensor_conditions',
    'zone_groups',
    'zones',
    'customer_types',
    'customers',
    'service_id',
    'detection',
    'apply_zone_condition',
    'zone_condition',
    'volume',
  ];

  get sensor(): string {
    return this._sensor;
  }

  set sensor(value: string) {
    this._sensor = value;
  }

  get time_minutes(): any {
    return this._time_minutes;
  }

  set time_minutes(value: any) {
    this._time_minutes = value;
  }

  get no_conn_period_min(): number {
    return this._no_conn_period_min;
  }

  set no_conn_period_min(value: number) {
    this._no_conn_period_min = value;
  }

  get speed_limit(): number {
    return this._speed_limit;
  }

  set speed_limit(value: number) {
    this._speed_limit = value;
  }
  get volume(): number {
    return this._volume;
  }

  set volume(value: number) {
    this._volume = value;
  }

  get speed_diff(): number {
    return this._speed_diff;
  }

  set speed_diff(value: number) {
    this._speed_diff = value;
  }

  get angle_diff(): number {
    return this._angle_diff;
  }

  set angle_diff(value: number) {
    this._angle_diff = value;
  }

  get min_speed(): number {
    return this._min_speed;
  }

  set min_speed(value: number) {
    this._min_speed = value;
  }

  get sensor_conditions(): SensorConditions[] {
    return this._sensor_conditions;
  }

  set sensor_conditions(value: SensorConditions[]) {
    this._sensor_conditions = value;
  }

  get zones(): string[] {
    return this._zones;
  }

  set zones(value: string[]) {
    this._zones = value;
  }

  get zone_groups(): string[] {
    return this._zone_groups;
  }

  set zone_groups(value: string[]) {
    this._zone_groups = value;
  }

  get customers(): string[] {
    return this._customers;
  }

  set customers(value: string[]) {
    this._customers = value;
  }

  get customer_types(): string[] {
    return this._customer_types;
  }

  set customer_types(value: string[]) {
    this._customer_types = value;
  }

  get service_id(): string {
    return this._service_id;
  }

  set service_id(value: string) {
    this._service_id = value;
  }
  get detection(): string {
    return this._detection;
  }

  set detection(value: string) {
    this._detection = value;
  }
  get apply_zone_condition(): boolean {
    return this._apply_zone_condition;
  }

  set apply_zone_condition(value: boolean) {
    this._apply_zone_condition = value;
  }

  get zone_condition(): string[] {
    return this._zone_condition;
  }

  set zone_condition(value: string[]) {
    this._zone_condition = value;
  }
}

export class Events extends AppModel {
  private _user_id: string;
  private _type: string;
  private _name: string;
  private _status: boolean = true;
  private _violation_penalty: boolean;
  private _notify: Notify = new Notify();
  private _action: Action = new Action();
  private _limit: Limit = new Limit();
  private _event_conditions: EventConditions = new EventConditions();
  private _created_by: string;
  private _units: string[];
  private _unit_groups: string[];
  private _created_at: Date;
  private _updated_at: Date;
  private _deleted_at: Date;
  private _typeDisplay: string;
  private _allowed_device_types: string;

  protected copyFields = [
    'id',
    'user_id',
    'type',
    'name',
    'allowed_device_types',
    'violation_penalty',
    'status',
    'notify',
    'action',
    'limit',
    'event_conditions',
    'created_by',
    'created_at',
    'updated_at',
    'deleted_at',
    'users_list',
    'units',
    'unit_groups',
  ];
  protected createFields = [
    'type',
    'name',
    'allowed_device_types',
    'violation_penalty',
    'status',
    'notify',
    'action',
    'limit',
    'event_conditions',
    'units',
    'unit_groups',
    'users_list',
  ];
  protected updateFields = [
    'type',
    'name',
    'allowed_device_types',
    'violation_penalty',
    'status',
    'notify',
    'action',
    'limit',
    'event_conditions',
    'units',
    'unit_groups',
    'users_list',
  ];
  constructor(protected translate: TranslateService) {
    super();
  }

  get units(): string[] {
    return this._units;
  }

  set units(value: string[]) {
    this._units = value;
  }

  public get allowed_device_types(): string {
    return this._allowed_device_types;
  }
  public set allowed_device_types(value: string) {
    this._allowed_device_types = value;
  }
  get unit_groups(): string[] {
    return this._unit_groups;
  }

  set unit_groups(value: string[]) {
    this._unit_groups = value;
  }

  get user_id(): string {
    return this._user_id;
  }

  set user_id(value: string) {
    this._user_id = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get status(): boolean {
    return this._status;
  }

  set status(value: boolean) {
    this._status = value;
  }

  get violation_penalty(): boolean {
    return this._violation_penalty;
  }

  set violation_penalty(value: boolean) {
    this._violation_penalty = value;
  }

  get notify(): Notify {
    return this._notify;
  }

  set notify(value: Notify) {
    let sms_numbers = [];
    let emails = [];
    if (value) {
      for (let k in value.sms_numbers) {
        if (typeof value.sms_numbers[k] == 'object') {
          sms_numbers.push(value.sms_numbers[k]['value']);
        } else {
          sms_numbers.push(value.sms_numbers[k]);
        }
      }
      value.sms_numbers = sms_numbers;
      for (let k in value.emails) {
        if (typeof value.emails[k] == 'object') {
          emails.push(value.emails[k]['value']);
        } else {
          emails.push(value.emails[k]);
        }
      }
      value.emails = emails;
    }
    this._notify = value;
  }

  get action(): Action {
    return this._action;
  }

  set action(value: Action) {
    this._action = value;
  }

  get limit(): Limit {
    return this._limit;
  }

  set limit(value: Limit) {
    // let days = [];
    // if (value) {
    //     for (let k in value.days) {
    //         if (value.days[k]) {
    //             days.push(parseInt(k) + 1);
    //         } else {
    //             days.push(false);
    //         }
    //     }
    //     value.days = days;
    // }
    this._limit = value;
  }

  get created_by(): string {
    return this._created_by;
  }

  set created_by(value: string) {
    this._created_by = value;
  }

  get created_at(): Date {
    return this._created_at;
  }

  set created_at(value: Date) {
    this._created_at = value;
  }

  get updated_at(): Date {
    return this._updated_at;
  }

  set updated_at(value: Date) {
    this._updated_at = value;
  }

  get deleted_at(): Date {
    return this._deleted_at;
  }

  set deleted_at(value: Date) {
    this._deleted_at = value;
  }

  get typeDisplay(): string {
    return this.translate.instant('events.' + this.type);
  }

  get eventTypeDisplay() {
    let self = this;
    let type = EventsSettings.eventTypes.find(function (item) {
      return item.value == self.type;
    });
    return type && type['display'] ? 'events.' + type['display'] : self.type;
  }

  get event_conditions(): EventConditions {
    return this._event_conditions;
  }

  set event_conditions(value: EventConditions) {
    this._event_conditions = value;
  }

  get mobile() {
    return this.notify.mobile;
  }

  get sms() {
    return this.notify.sms;
  }

  get system() {
    return this.notify.system;
  }

  get email() {
    return this.notify.email;
  }

  set mobile(value) {
    this._notify.mobile = value;
  }

  set sms(value) {
    this._notify.sms = value;
  }

  set system(value) {
    this._notify.system = value;
  }

  set email(value) {
    this._notify.email = value;
  }

  get statusType() {
    return this.translate.instant(this.eventStatusDisplay(this.status));
  }

  get emailType() {
    return this.translate.instant(this.eventStatusDisplay(this.email));
  }

  get smsType() {
    return this.translate.instant(this.eventStatusDisplay(this.sms));
  }

  get systemType() {
    return this.translate.instant(this.eventStatusDisplay(this.system));
  }

  get mobileType() {
    return this.translate.instant(this.eventStatusDisplay(this.mobile));
  }

  private eventStatusDisplay(value: boolean | number): string {
    return 'events.' + EventsSettings.statusVal[value ? 1 : 0];
  }
}
