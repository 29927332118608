import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';
import { AuthService, RootService } from 'app/core';
import { UnitService } from 'app/modules/units/services';
import { AppModel, Message } from 'app/common';
import { ActivatedRoute } from '@angular/router';
import { Unit } from 'app/modules/units/models';
import { takeWhile } from 'rxjs/operators';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import { WjFlexGridFilter } from '@grapecity/wijmo.angular2.grid.filter';

@Component({
  selector: 'unit-form-sub',
  template: '',
})
export class UnitFormBaseSubComponent implements OnChanges, OnInit, OnDestroy {
  @Input() unitObject: Unit;
  @Input() refresh: any;
  @Input() resourceID: any;
  @Input() isAdd?: boolean;
  cid: string;
  alive: boolean = true;
  itemsList: wjcCore.CollectionView = new wjcCore.CollectionView([]);
  message: Message = new Message();
  controller: string;
  loading = false;
  filtersCols = ['name'];
  showForm = false;
  showClone = false;
  selectedItem: AppModel;
  isView: boolean = false;
  isCopy: boolean = false;
  editPermission: boolean = false;

  moduleKey: string;
  updatingId: string;

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public service: RootService,
    public unitService: UnitService
  ) {}

  public ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    this.loadItems();
    if (this.authService.checkPermissions(this.controller + '-edit', '')) {
      this.editPermission = true;
    }
  }

  openForm() {
    this.showForm = true;
  }

  openClone() {
    this.showClone = true;
  }

  loadItems() {
    this.itemsList.sourceCollection = this.unitObject?.[this.moduleKey];
  }

  ngOnChanges() {
    this.itemsList.refresh();
  }

  closeForm(event) {
    this.showForm = false;
    if (event && event['success'] && event['msg']) {
      this.message.dismissible = true;
      this.message.timeout = 3000;
      this.message.type = event['type'];
      this.message.message = event['msg'];
    }
  }

  closeClone(event) {
    this.showClone = false;
    if (event && event['success'] && event['msg']) {
      this.message.dismissible = true;
      this.message.timeout = 3000;
      this.message.type = event['type'];
      this.message.message = event['msg'];
    }
  }

  addForm() {
    this.isView = false;
    this.updatingId = null;
  }

  clone(index, item) {
    this.updatingId = item.id;
    this.selectedItem = item;
    this.isView = false;
    this.openClone();
  }

  edit(index, item) {
    this.isView = false;
    this.updatingId = item.id;
    this.selectedItem = item;
    this.openForm();
  }

  view(index, item) {
    this.isView = true;
    this.updatingId = item.id;
    this.selectedItem = item;
    this.openForm();
  }

  copy(item) {
    this.isView = false;
    this.isCopy = true;
    this.updatingId = null;
    this.selectedItem = item.clone();
    this.selectedItem.id = '';
    this.openForm();
  }

  update(event) {
    // console.log('sourceCollection', this.itemsList.sourceCollection);
    // console.log('updatingIndex', this.updatingId);
    // console.log('object', event.object);
    if (this.updatingId) {
      let itemIndex = this.itemsList.sourceCollection.findIndex(
        (x) => x.id === this.updatingId
      );
      if (itemIndex > -1) {
        this.itemsList.sourceCollection[itemIndex] = event.object;
        this.itemsList.refresh();
      }
    } else {
      this.itemsList.sourceCollection = this.itemsList.sourceCollection.concat([
        event.object,
      ]);
    }
    this.unitObject[this.moduleKey] = this.itemsList.sourceCollection;
    this.showForm = false;
    this.showClone = false;
    this.selectedItem = new AppModel();
  }

  deleteItem(item): void | boolean {
    if (this.unitObject.id) {
      const id = item['id'];
      let subscription = this.service
        .confirm('confirm-delete')
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (response) => {
            if (response) {
              this.loading = true;
              this.service.delete(id).subscribe({
                next: (response) => {
                  this.afterDeleteSuccess(item, response);
                },
                error: (error) => {
                  this.afterDeleteError(item, error);
                },
                complete: () => {
                  this.loading = false;
                },
              });
            }
            subscription.unsubscribe();
          },
        });
      return false;
    } else {
      this.itemsList.remove(item);
    }
  }

  afterDeleteSuccess(item: any, response?: any) {
    this.message.fill(
      `notifications.${this.controller}.deleted`,
      '',
      'success'
    );
    this.itemsList.remove(item);
  }
  afterDeleteError(item: any, error: any) {
    this.message.fill('notifications.tryagain', '', 'error');
  }
}
