import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { takeWhile } from 'rxjs/operators';
import { AppConfig, Message } from '../../../common/classes';
import { AfaqyAPIResponse } from '../../classes';
import { AuthService } from '../../services';

import { transition, trigger, useAnimation } from '@angular/animations';
import 'assets/js/particles.js';
import * as particlesOptions from 'assets/json/particles.json';
import { AnimationOptions } from 'ngx-lottie';
import {
  HideAnimation,
  showAnimation,
} from '../../../shared/animations/scale-show-hide';
import { NgForm } from '@angular/forms';
import { passwordStrengthValidator } from 'app/shared/validators/password-strength.validator';

declare var particlesJS: any;

@Component({
  selector: 'reset',
  styleUrls: ['./reset.component.scss'],
  templateUrl: './reset.component.html',
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: { timings: '300ms 200ms ease-in-out' },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: { timings: '200ms ease-in-out' },
        }),
      ]),
    ]),
  ],
})
export class ResetComponent implements OnInit {
  alive: boolean = true;
  model: any = {};
  loading = false;
  code_invalid = false;
  user_name_invalid = false;
  password_invalid = false;

  configs = AppConfig;
  login_anmation_src: string = 'assets/login.json';
  domain: string = window.location.href;

  @HostBinding('@showHide')
  animation = true;
  public lottieConfig: AnimationOptions;
  private anim: any;
  private animationSpeed: number = 1;
  loadedSettings = false;

  // syslang: string;
  constructor(
    private router: Router,
    private authService: AuthService,
    public message: Message
  ) {
    if (this.domain.includes('isuzu')) {
      this.login_anmation_src = 'assets/login_isuzu.json';
    }

    this.lottieConfig = {
      path: this.login_anmation_src,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      rendererSettings: {
        className: 'BgAnimation',
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }

  ngOnInit() {
    this.loadedSettings = this.authService.isSettingLoaded;
    this.authService.loadedSettings.subscribe({
      next: () => {
        this.loadedSettings = true;
      },
    });
    particlesJS('particles-js', particlesOptions, null);
  }

  reset() {
    this.loading = true;
    this.message.clear();
    this.authService
      .reset(this.model.code, this.model.password, this.model.user_name)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (response: AfaqyAPIResponse) => {
          if (response.status_code == 200) {
            this.loading = false;
            this.message.clear();
            this.router.navigate(['/auth/login']);
          } else {
            this.message.type = 'danger';
            this.message.message = response.message;
            if (response.errors.code) this.code_invalid = true;
            else this.code_invalid = false;

            if (response.errors.user_name) this.user_name_invalid = true;
            else this.user_name_invalid = false;

            if (response.errors.password) this.password_invalid = true;
            else this.password_invalid = false;

            this.loading = false;
          }
        },
        error: (error) => {
          this.message.type = 'danger';
          this.message.message = error.message;
          this.loading = false;
        },
      });
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  onPasswordChange(form: NgForm): void {
    const passwordControl = form.controls['password'];
    const validationErrors = this.validatePassword(passwordControl);
    passwordControl.setErrors(validationErrors);
  }

  validatePassword(control: any): any {
    const value = control.value;
    if (!value) return null;
    const errors = passwordStrengthValidator('unifiedPasswordError').bind(this)(
      control
    );
    this.password_invalid = errors?.unifiedPasswordError ? true : false;
    return errors;
  }
}
