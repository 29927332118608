import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validator function to check the strength of a password.
 * Ensures the password contains at least one uppercase letter, one lowercase letter,
 * one numeric digit, one special character, and is at least 8 characters long.
 *
 * @returns A ValidatorFn that returns a validation error object if the password is weak, otherwise null.
 */
export function passwordStrengthValidator(
  customErrorTranslationKey?: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const isValidLength = value.length >= 12;

    const passwordValid =
      hasUpperCase &&
      hasLowerCase &&
      hasNumeric &&
      hasSpecialChar &&
      isValidLength;
    if (!passwordValid) {
      if (customErrorTranslationKey)
        return { [customErrorTranslationKey]: true };
      else
        return {
          passwordStrength: {
            hasUpperCase,
            hasLowerCase,
            hasNumeric,
            hasSpecialChar,
            isValidLength,
          },
        };
    }
    return null;
  };
}

/**
 * Validator function to check if the password and confirm password fields match.
 *
 * @param password The form control name for the password.
 * @param confirmPassword The form control name for the confirm password.
 * @returns A ValidatorFn that returns a validation error object if the passwords do not match, otherwise null.
 */
export function matchPasswords(
  password: string,
  confirmPassword: string
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const passwordInput = group.get(password);
    const confirmPasswordInput = group.get(confirmPassword);

    if (
      passwordInput &&
      confirmPasswordInput &&
      passwordInput.value !== confirmPasswordInput.value
    ) {
      return { passwordsNotMatching: true };
    }

    return null;
  };
}
