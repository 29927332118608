<ng-select
  [class.has-danger]="(ctl?.dirty || ctl?.touched) && ctl?.errors"
  [disabled]="isDisabled"
  [items]="itemsList"
  [multiple]="multiple"
  [isOpen]="isOpen"
  [clearable]="clear || multiple"
  (clear)="onClear()"
  [virtualScroll]="true"
  [bindLabel]="titleField"
  bindValue="id"
  (change)="pushValue()"
  [(ngModel)]="selectedList"
  (blur)="onBlur()"
  [closeOnSelect]="!multiple"
  [searchFn]="hasCustomFilter ? customFilter : null"
  [placeholder]="placeholderText"
  [maxSelectedItems]="_maxSelectedItems"
  [class.max-selected]="selectedList?.length >= _maxSelectedItems"
>
  <ng-container *ngIf="multiple">
    <ng-template
      ng-option-tmp
      let-item="item"
      let-item$="item$"
      let-index="index"
    >
      <input
        id="item-{{ index }}"
        type="checkbox"
        [disabled]="
          selectedList?.length === _maxSelectedItems && !item$?.selected
        "
        [ngModel]="item$.selected"
      />
      {{ item[titleField] }}
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div
        class="ng-value"
        *ngFor="let item of items ? items.slice(0, showMoreAfter) : []"
      >
        <span class="ng-value-label"> {{ item[titleField] }}</span>
        <span
          class="ng-value-icon right"
          (click)="clear(item)"
          aria-hidden="true"
          >×</span
        >
      </div>
      <div class="ng-value" *ngIf="items.length > showMoreAfter">
        <span class="ng-value-label" [title]="setMoreItems(items)"
          >{{ items.length - showMoreAfter }}
          {{ 'custom_select.more' | translate }} ...</span
        >
      </div>
    </ng-template>
    <ng-template ng-header-tmp *ngIf="!hideSelectAll">
      <button
        (click)="selectAll()"
        [attr.testId]="'selectAll'"
        class="btn btn-sm"
      >
        {{ 'custom_select.select_all' | translate }}
      </button>
      <button
        (click)="unselectAll()"
        [attr.testId]="'unselectAll'"
        class="btn btn-sm"
      >
        {{ 'custom_select.unselect_all' | translate }}
      </button>
    </ng-template>
  </ng-container>
</ng-select>
<control-errors-hint [ctl]="ctl"></control-errors-hint>
