import { AfaqyObject } from '../../../common/classes';

export class AppsRoutes extends AfaqyObject {
  private _enable: boolean = false;
  private _job_order_detection_type: string = '';

  protected copyFields = ['enable', 'job_order_detection_type'];

  get enable(): boolean {
    return this._enable;
  }

  set enable(value: boolean) {
    this._enable = value;
  }
  get job_order_detection_type(): string {
    return this._job_order_detection_type;
  }

  set job_order_detection_type(value: string) {
    this._job_order_detection_type = value;
  }
}
