import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OTPVerification, UserApp } from 'app/shared/interfaces/interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AfaqyHelper, AppConfig } from '../../common/classes';
import { User } from '../../modules/users/models';
import { AfaqyAPIResponse } from '../classes/afaqy-response';
import { SessionStatusEnum } from '../enums';
import { LoginService } from './login.service';
import { RxNotificationsStompService } from './stomp-socket-services/rx-notifications-stomp.service';
import { RxUnitsStompService } from './stomp-socket-services/rx-units-stomp.service';
import * as moment from 'moment';
@Injectable({ providedIn: 'root' })
export class AuthService {
  private firstLoad = false;
  private sessionData = {
    isAdmin: false,
    isOperation: false,
    isWASL: false,
    rootID: '',
    expireTime: new Date(),
    user: new User(),
    lang: '',
    color: '',
    permissions: [],
    preferences: {},
    loggedAsUser: {},
  };
  cctvSessionsList = {};
  private _redirectUrl: string;
  // private _soundStatus:boolean;

  private _sessionObserver: Subject<any> = new Subject<any>();

  public _sessionStatusObserver: BehaviorSubject<SessionStatusEnum> =
    new BehaviorSubject<SessionStatusEnum>(SessionStatusEnum.LOADING);
  public loadedSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public userUnitsLoaded: Subject<any> = new Subject<any>();
  public userDetails: Subject<any> = new Subject<any>();
  public loadedSettings: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public isSettingLoaded = false;
  private _actionsByDefaultDisabled = [];
  public currencyUpdate$: BehaviorSubject<any> = new BehaviorSubject('');
  sessionFinalizedResolver: BehaviorSubject<any> = new BehaviorSubject(false);
  updateTimeZone = new BehaviorSubject('');
  timeZone: any;
  currency: string;
  userSessionReady = new BehaviorSubject<boolean>(false);
  authScope: string;

  passwordExpired: BehaviorSubject<{ expired: boolean; username?: string }> =
    new BehaviorSubject<{ expired: boolean; username?: string }>({
      expired: false,
    });
  // is_2fa_enabled = false;
  // userVerification = {
  //   data: {
  //     one_time_token:
  //       'v5v3yDSqVYftqWiRnXkIxNCrHVdPIYNgj9EfnVPPiaS8O7Lm8y3YpBu9lXdipUruJvoRPeYhuO2G9OCCSvRDlM6epeiSbaKnfHDF',
  //     methods: [
  //       {
  //         type: 'phone',
  //         value: '+966*****456',
  //       },
  //       {
  //         type: 'email',
  //         value: '2fa@*****.com',
  //       },
  //     ],
  //     is_2fa: true,
  //   },
  // };
  otpVerificationModel: OTPVerification = {
    type: null,
    method: {
      phone: {
        value: null,
        disabled: false,
        resend_expires_In: null,
        verify_expires_In: null,
        otpCode: null,
        disclaimerMessage: null,
        verified: false,
        blocked: false,
      },
      email: {
        value: null,
        disabled: false,
        resend_expires_In: null,
        verify_expires_In: null,
        otpCode: null,
        disclaimerMessage: null,
        verified: false,
        blocked: false,
      },
    },
  };
  preventLoginAs = true;
  constructor(
    private translate: TranslateService,
    private loginService: LoginService,
    private router: Router,
    private locationStrategy: LocationStrategy,
    private rxUnitsStompService: RxUnitsStompService,
    private rxNotificationsStompService: RxNotificationsStompService
  ) {
    if (this.checkBaseDomain()) {
      this.router.navigate(['/auth/redirect']);
      return;
    }
    this.loadSettings();

    //this actions not exists and should be disabled for admin
    this._actionsByDefaultDisabled = [
      'tokens-add',
      'tokens-edit',
      'tokens-view',
      'tokens-import',
      'tokens-export',
      'tokens-trashed',
    ];
    this.currencyUpdate$.subscribe({
      next: (currency) => {
        this.currency = currency;
      },
    });
  }

  loadSettings() {
    let domain = window.location.hostname;
    this.loadedSettings.next(false);
    this.loginService
      .doPost('settings', { domain: domain, keys: 1 })
      .subscribe({
        next: (result: AfaqyAPIResponse) => {
          for (const key in result.data) {
            AppConfig[key] = result.data[key];
          }
          AppConfig.langs = ['en', 'ar'];
          AppConfig.KEYSMAP = result.extra.keysList;
          const host = window.location.hostname;
          AppConfig.isCMS = host == 'cms.' + AppConfig.sysDomain ? true : false;
          document
            .getElementById('favIconID')
            .setAttribute('href', AppConfig.favicon);
          this.isSettingLoaded = true;
          this.loadedSettings.next(true);
          AppConfig.settingsLoaded = true;
          if (this.authToken) {
            this.loadSessionData('/');
          }
        },
        error: (err) => {
          if (err) {
            this.redirectTOLogin('Invalid Session');
          }
        },
      });
  }

  refactorUserData(user: any): User {
    const mapUtilities = user?.map?.show_utilities;
    let userPref = user?.preferences?.map_main?.pressedUtilitiesBtns;
    if (
      mapUtilities &&
      userPref &&
      !mapUtilities?.includes('showCluster') &&
      userPref?.includes('showCluster')
    ) {
      userPref = userPref.filter((x) => x !== 'showCluster');
      user.preferences.map_main.pressedUtilitiesBtns = userPref;
    }
    if (
      mapUtilities &&
      userPref &&
      !mapUtilities?.includes('showZoneCluster') &&
      userPref?.includes('showZoneCluster')
    ) {
      userPref = userPref.filter((x) => x !== 'showZoneCluster');
      user.preferences.map_main.pressedUtilitiesBtns = userPref;
    }

    return user;
  }

  loadSessionData(navigateTo?: string, scope = 'any') {
    // console.log('here');
    // console.log(navigateTo, scope)
    const hURL = window.location.pathname;
    if (navigateTo != hURL) {
      navigateTo = hURL;
    } else {
      navigateTo = '/';
    }
    if (this.authToken) {
      this.loginService.session(this.authToken).subscribe({
        next: (response: AfaqyAPIResponse) => {
          if (response.status_code == 200) {
            this.loggedAsUser = response.data.loggedAsUser;
            let expireDate = new Date(response.data.expire);
            this.expireTime = expireDate;
            this.updateSessionData(response, scope);
            this.fireSessionStatus();
            this.updateTimeZone.next(response.data.user.timezone);
            // this.getUpdatedTimeZone();
            if (hURL !== '/auth/login') {
              this.setRedirectUrl(hURL);
            }
            const userActiveLanguage =
              response?.data?.user?.preferences?.site?.lang ??
              AppConfig.defaultLang;
            this.translate.use(this.trimSuffix(userActiveLanguage));
            if (navigateTo && scope == 'any') {
              this.router.navigate([navigateTo], {
                skipLocationChange: AppConfig.skipLocationChange,
              });
            } else if (navigateTo && scope == 'login') {
              this.router.navigate(['/']).then(() => {
                AfaqyHelper.clearBrowserHistory();
                this.preventBackButton();
              });
            } else if (scope == 'validate') {
              this._sessionStatusObserver.next(SessionStatusEnum.ACTIVE);
            } else {
              this.redirectToSession();
            }
            this.loadedSession.next(true);
            this.sessionFinalizedResolver.next(true);

            /**
             * Load user's language from user preferences and emit it to langChanges subject
             */
          } else {
            this.loadedSession.next(false);
            this._sessionStatusObserver.next(SessionStatusEnum.EXPIRED);
            // if (window.location.pathname != '/login') {
            //     window.location.href = '/login';
            // }
          }
        },
        error: (error) => {
          // Logger.error(error);
          // console.log(error)
          this._sessionStatusObserver.next(SessionStatusEnum.INVALID);
          this.loadedSession.next(false);
          if (error?.error?.message !== 'password_expired') {
            if (this.userType === 'loggedAsUser' || this.loggedOutSession) {
              this.logoutLoggedAs();
            } else {
              this.redirectTOLogin('Invalid Session');
            }
          }
          this.sessionFinalizedResolver.next(false);
        },
      });
    } else {
      this.authToken = undefined;
      this.isLoggedIn = false;
      this.loadedSession.next(false);
      this._sessionStatusObserver.next(SessionStatusEnum.INVALID);
      this.redirectTOLogin('Invalid Session');
      if (hURL.includes('/share-locations')) {
        this.router.navigate([hURL]);
      }
      this.sessionFinalizedResolver.next(false);
    }
  }

  checkBaseDomain() {
    const originDomain = window.location.origin;
    const domainArray = originDomain.includes('afaqy.sa');
    return domainArray;
  }

  setRedirectUrl(URL = '/') {
    this.redirectUrl =
      URL == '' || URL == '/' ? this.preferences('site', 'page', '/') : URL;
  }

  isRTLLang(lng = '') {
    if (!lng) {
      lng = this.translate.currentLang;
    }
    // return AppConfig.cssrtl.langs.includes(lng);
    return false;
  }

  get colors() {
    return [
      {
        name: 'theme_1',
        code: '#0C6667',
        abs_highlight: '12, 102, 103',
        active: false,
      },
      {
        name: 'theme_2',
        code: '#AD1457',
        abs_highlight: '173, 20, 87',
        active: true,
      },
      {
        name: 'theme_3',
        code: '#718093',
        abs_highlight: '113, 128, 147',
        active: false,
      },
      {
        name: 'theme_4',
        code: '#cc8e35',
        abs_highlight: '204, 142, 53',
        active: false,
      },
      {
        name: 'theme_5',
        code: '#353b48',
        abs_highlight: '53, 59, 72',
        active: false,
      },
      {
        name: 'theme_6',
        code: '#1E1E4F',
        abs_highlight: '30, 30, 79',
        active: false,
      },
      {
        name: 'theme_7',
        code: '#10ac84',
        abs_highlight: '16, 172, 132',
        active: false,
      },
      {
        name: 'theme_8',
        code: '#00838F',
        abs_highlight: '0, 131, 143',
        active: false,
      },
      {
        name: 'theme_9',
        code: '#009432',
        abs_highlight: '0, 148, 50',
        active: false,
      },
      {
        name: 'theme_10',
        code: '#3E2723',
        abs_highlight: '62, 39, 35',
        active: false,
      },
      {
        name: 'theme_11',
        code: '#0a3d62',
        abs_highlight: '10, 61, 98',
        active: false,
      },
      {
        name: 'theme_12',
        code: '#2c2c54',
        abs_highlight: '44, 44, 84',
        active: false,
      },
      {
        name: 'theme_13',
        code: '#ed1c24',
        abs_highlight: '237, 28, 36',
        active: false,
      },
      {
        name: 'theme_14',
        code: '#95C11F',
        abs_highlight: '149, 193, 31',
        active: false,
      },
      {
        name: 'theme_15',
        code: '#01a4ef',
        abs_highlight: '1, 164, 239',
        active: false,
      },
    ];
  }

  redirectTOLogin(s = '') {
    this.logout();
    // close any open popup
    this.router.navigate([{ outlets: { popup: null } }]).then(() => {
      // Navigate to the login page
      this.router.navigate(['/auth/login']);
    });
  }

  redirectToSession() {
    if (AppConfig.isCMS) {
      this.router.navigate(['/portal'], {
        skipLocationChange: AppConfig.skipLocationChange,
      });
    } else {
      this.redirectTo();
    }
  }

  redirectTo(url = '', skipLocationChange = AppConfig.skipLocationChange) {
    const redirectURL = url || this.redirectUrl || '/';
    this.redirectUrl = '';
    this.router.navigate([redirectURL], {
      skipLocationChange: skipLocationChange,
    });
    AfaqyHelper.clearBrowserHistory();
  }

  get colorDetails() {
    const color = this.color;
    return this.colors.find((item) => {
      return item.name == color;
    });
  }

  fireSessionStatus() {
    this.sessionObserver.next(this.isActiveLogin());
    // this.loadedSession.next(this.isActiveLogin());
  }

  isActiveLogin() {
    return !!(this.isLoggedIn && this.authToken);
  }

  isRoot() {
    return !this.user.parent_id;
  }

  isTokenActive() {
    const expireDate = this.expireTime;
    const current = new Date();
    return current <= expireDate;
  }

  getUserPermissions(force = false) {
    if (!force && this.permissions) {
      return this.permissions;
    }
    const userPermissions = this.user.permissions || [];
    const rolePermissions = this.user.role.permissions || [];
    this.permissions = rolePermissions.concat(userPermissions);
    return this.permissions;
  }

  getLimit(): number {
    const limit = this.preferences('pagination', 'limit');
    if (limit) {
      return limit;
    }
    return AppConfig.defaultPaginationLimit;
  }

  updateLimit(limit) {
    this.updatePreferences('pagination', 'limit', limit);
  }

  preferences(module, key = '', defaultVal?: any) {
    if (key == '') {
      return this.sessionData.preferences[module]
        ? this.sessionData.preferences[module]
        : defaultVal;
    }
    if (
      this.sessionData.preferences[module] &&
      (this.sessionData.preferences[module][key] ||
        this.sessionData.preferences[module][key] === false)
    ) {
      return this.sessionData.preferences[module][key];
    }
    return defaultVal as any;
  }

  updatePreferences(module, key = '', data: any) {
    if (!this.sessionData.preferences[module]) {
      this.sessionData.preferences[module] = {};
    }
    const oldValue =
      key == ''
        ? this.sessionData.preferences[module]
        : this.sessionData.preferences[module][key];
    let isValueNotChanged = JSON.stringify(oldValue) === JSON.stringify(data);
    if (key) {
      this.sessionData.preferences[module][key] = JSON.parse(
        JSON.stringify(data)
      );
    } else {
      this.sessionData.preferences[module] = JSON.parse(JSON.stringify(data));
    }
    if (this.isLoggedIn && !isValueNotChanged && this.authToken) {
      this.loginService
        .doPost('user/update_preferences?token=' + this.authToken, {
          module: module,
          key: key,
          value: data,
        })
        // .pipe(debounceTime(1000))
        .subscribe({
          next: (res) => {
            // Logger.log('update_preferences', module, key, data, res);
          },
        });
    }
  }

  trimSuffix(lng: string) {
    return lng.replace('-ltr', '').replace('-rtl', '');
  }

  checkPermissions(permissionKey: string, url: string = ''): boolean {
    //this actions not exists and should disabled for admin
    if (
      this.isAdmin &&
      this._actionsByDefaultDisabled.includes(permissionKey)
    ) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }
    const permissions = this.getUserPermissions();
    return permissions.find((pKey) => pKey === permissionKey) ? true : false;
  }

  checkWASLPermissions(permissionKey: string, url: string = ''): boolean {
    //this actions not exists and should disabled for admin
    if (
      this.isAdmin &&
      this._actionsByDefaultDisabled.includes(permissionKey)
    ) {
      return false;
    }

    if (this.isWASL && permissionKey.startsWith('WASL_Custome')) {
      return true;
    }
    return false;
  }

  updateSessionData(response, scope = 'any') {
    // console.log(response, scope)
    let user = new User();
    user.copyInto(this.refactorUserData(response.data.user), false);
    if (user) {
      const apps = response?.data?.user?.apps;
      if (apps) {
        const accounts_app = Object.entries(apps)
          .map((x: any) => {
            x[1].name = x[0];
            return x[1];
          })
          .filter((obj: UserApp) => obj['enable'] == true);
        user.account_apps = accounts_app ? accounts_app : [];
      }
      this.sessionData.preferences = response.data.user.preferences;
      this.rootID = response.data.user.rootID;
      this.isLoggedIn = true;
      this.user = user;
      // this for mocking data
      //-----------------------------------
      if (user.user_settings.currency) {
        this.currencyUpdate$.next(user.user_settings.currency);
      }
      this.saveUserInfo(user.username, user.email);
      this.getUserPermissions(true);
      this.color = this.preferences('site', 'color', 'default');
      if (response.data.loggedAsUser) {
        this.loggedAsUser = response.data.loggedAsUser;
      } else {
        this.loggedAsUser = {};
      }
      /**
       * let time interval fires for one time only per login action only and prevent loginAs intervals
       */
      AfaqyHelper.UpdateUserCurrentTime(moment.now());
      if (scope !== 'validate') {
        this.userSessionReady.next(true);
      }
    }
  }

  login(username: string, password: string, reload = true, lang = '') {
    const expireInHours = AppConfig.sessionTimeInHours;
    return this.loginService
      .login(username, password, expireInHours, lang)
      .pipe(
        map((result: AfaqyAPIResponse) => {
          if (result.status_code === 200) {
            if (!result.data?.is_2fa) {
              if (result.data.token) {
                this.authToken = result.data.token;
              }
            } else {
              this.oneTimeSession = result.data;
            }
            this.preventBackButton();
            // this.updateSessionData(result, reload);
            // this.setExpireTime();
            // this.setRedirectUrl();
          }
          return result;
        })
      );
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  loginAs(id: string, updateSession: boolean = false, lang = '') {
    const expireInHours = AppConfig.sessionTimeInHours;
    return this.loginService.loginAs(id, expireInHours, this.authToken).pipe(
      map((result: AfaqyAPIResponse) => {
        if (updateSession && result.status_code === 200) {
          this.setAuthScope('validate');
          this.authToken = result.data.token;

          if (updateSession) {
            /**
             * temporary solution to avoid circular dep auth > socket > auth problem
             * injecting the seperate socket services for each type notifications and units and deactivating them
             */
            this.rxUnitsStompService.deactivate();
            this.rxNotificationsStompService.deactivate();
          }

          // this.updateSessionData(result);
          // this.setExpireTime();
          // this.setRedirectUrl();
        }
        return result;
      })
    );
  }

  logout() {
    if (this.authToken && this.authToken !== '') {
      this.loginService.logout(this.authToken).subscribe();
    }
    AfaqyHelper.clearStorage();
    AfaqyHelper.clearBrowserHistory();

    this.user = new User();
    this.fireSessionStatus();
    this.sessionFinalizedResolver.next(false);
    AfaqyHelper.clearUserTimeInterval();
  }

  logoutLoggedAs() {
    AfaqyHelper.clearSessionStorage();
    sessionStorage.setItem('loggedOutSession', 'true');
    this.router.navigateByUrl('/auth/loggedOutSession');
  }

  setExpireTime() {
    let expireDate = new Date(AfaqyHelper.getUserCurrentTime());
    const newMinutes =
      expireDate.getMinutes() + AppConfig.sessionTimeInHours * 60;
    expireDate.setMinutes(newMinutes);
    this.expireTime = expireDate;
  }

  refreshExpireTime() {
    this.setExpireTime();
  }

  forgot(username: string) {
    return this.loginService.forgot(username).pipe(
      map((result: AfaqyAPIResponse) => {
        if (result.status_code === 200) {
          //this.setRedirectUrl();
        }
        return result;
      })
    );
  }

  reset(code: string, password: string, password_confirm: string) {
    return this.loginService.reset(code, password, password_confirm).pipe(
      map((result: AfaqyAPIResponse) => {
        if (result.status_code === 200) {
          //this.setRedirectUrl();
        }
        return result;
      })
    );
  }

  resetExpired(
    username: string,
    current_password: string,
    password: string,
    confirmed_password: string
  ) {
    return this.loginService
      .resetExpired(username, current_password, password, confirmed_password)
      .pipe(
        map((result: AfaqyAPIResponse) => {
          if (result.status_code === 200) {
            //this.setRedirectUrl();
          }
          return result;
        })
      );
  }

  set isLoggedIn(value: boolean) {
    // this.sessionData.isLoggedIn = value;
    if (this.authScope == 'validate') {
      sessionStorage.setItem('isLoggedIn', value ? '1' : '0');
    } else {
      localStorage.setItem('isLoggedIn', value ? '1' : '0');
    }
  }

  set redirectUrl(value: string) {
    this._redirectUrl = value;
  }

  set oneTimeSession(value: any) {
    localStorage.setItem('one-time-model', JSON.stringify(value));
  }
  get oneTimeSession() {
    return localStorage.getItem('one-time-model');
  }

  set otpModel(value: any) {
    localStorage.setItem('otpModel', JSON.stringify(value));
  }
  get otpModel() {
    return localStorage.getItem('otpModel');
  }

  setAuthScope(scope?: string) {
    this.authScope = scope;
  }

  set authToken(value: string) {
    if (value) {
      if (this.authScope === 'validate') {
        sessionStorage.setItem('token', value);
        sessionStorage.setItem('userType', 'loggedAsUser');
      } else {
        localStorage.setItem('token', value);
        localStorage.setItem('userType', 'parentUser');
      }
    }
  }

  set loggedAsUser(value: any) {
    if (this.authScope == 'validate') {
      sessionStorage.setItem('loggedAsUser', JSON.stringify(value));
    } else {
      localStorage.setItem('loggedAsUser', JSON.stringify(value));
    }
  }

  set user(user: User) {
    if (user) {
      this.setUserName(user.username);
    }
    this.sessionData.user = user;
    this.sessionData.isAdmin = user && user.role && user.role.admin;
    this.isOperation = user?.role?.operation;
    this.sessionData.isWASL = user && user.role && user.role.wasl;
  }

  set color(value: string) {
    this.sessionData.color = value;
    this.updatePreferences('site', 'color', value);
  }

  get userID(): string {
    return this.user.id;
  }

  get rootID(): string {
    return this.sessionData.rootID;
  }

  set rootID(value: string) {
    this.sessionData.rootID = value;
  }

  set permissions(value: string[]) {
    this.sessionData.permissions = value;
  }

  get permissions(): string[] {
    return this.sessionData.permissions;
  }

  get transLang(): string {
    return this.translate.currentLang.replace('-ltr', '').replace('-rtl', '');
  }

  get color(): string {
    if (!this.sessionData.color) {
      this.sessionData.color = 'default';
    }
    return this.sessionData.color;
  }

  get isLoggedIn(): boolean {
    let returnValue;

    if (this.authScope == 'validate') {
      returnValue = Boolean(sessionStorage.getItem('isLoggedIn'));
    } else {
      returnValue = Boolean(localStorage.getItem('isLoggedIn'));
    }
    return returnValue;
  }

  get redirectUrl(): string {
    return this._redirectUrl;
  }

  get sessionObserver(): any {
    return this._sessionObserver;
  }

  set sessionObserver(value) {
    this._sessionObserver = value;
  }

  get authToken(): string {
    let value: string;

    if (this.userType == 'parentUser') {
      value = localStorage.getItem('token');
    } else if (this.userType == 'loggedAsUser') {
      value = sessionStorage.getItem('token');
    }

    return value;
  }

  get userType(): string {
    const userType =
      sessionStorage.getItem('userType') || localStorage.getItem('userType');
    return userType;
  }
  get loggedOutSession(): string {
    return sessionStorage.getItem('loggedOutSession');
  }

  get loggedAsUser() {
    return JSON.parse(sessionStorage.getItem('loggedAsUser'));
  }

  get user(): User {
    return this.sessionData.user;
  }

  get isAdmin(): boolean {
    return this.sessionData.isAdmin;
  }

  set isAdmin(value: boolean) {
    this.sessionData.isAdmin = value;
  }

  get isOperation(): boolean {
    return this.sessionData.isOperation;
  }

  set isOperation(value: boolean) {
    this.sessionData.isOperation = value;
  }

  get isWASL(): boolean {
    return this.sessionData.isWASL;
  }

  set isWASL(value: boolean) {
    this.sessionData.isWASL = value;
  }

  get expireTime() {
    return this.sessionData.expireTime;
  }

  set expireTime(value) {
    this.sessionData.expireTime = value;
  }

  hasApp(key) {
    return this.user.account_apps?.some((x) => {
      return x.name === key && x.enable;
    });
  }

  hasCCTVSession(userID) {
    return this.cctvSessionsList.hasOwnProperty(userID);
  }

  setCCTVSession(userID, session) {
    this.cctvSessionsList[userID] = session;
  }

  getCCTVSession(userID) {
    return this.cctvSessionsList[userID];
  }

  /** Save username and email in local storage. */
  private saveUserInfo(username: string, email: string): void {
    if (this.authScope == 'validate') {
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('email', email);
    } else {
      localStorage.setItem('username', username);
      localStorage.setItem('email', email);
    }
  }

  getUpdatedTimeZone() {
    this.updateTimeZone.subscribe({
      next: (timeZone: any) => {
        if (timeZone) {
          this.user.timezone = timeZone;
          this.timeZone = timeZone;
        }
      },
    });
  }

  /**
   * If user data has been changed, check if the user info is stored in session storage (loggedAs) and store the username
   */
  setUserName(username: string) {
    // if sessionStorage is holding loggedAsUser object and it has data, then user is logged in as another user and not a parent user
    const sessionUserName = JSON.parse(sessionStorage.getItem('loggedAsUser'));

    if (typeof sessionUserName === 'object' && sessionUserName !== null) {
      sessionStorage.setItem('username', username);
    }
  }
}
