import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MissingTranslationHandler } from '@ngx-translate/core';
import { AppConfig } from './common/classes';
import { MyMissingTranslationHandler } from './shared/modules/missing-translation';
import { v4 as uuidv4 } from 'uuid';

export function createTranslateLoader(http: HttpClient) {
  // Generate a random string for the cache busting
  const randomString = uuidv4();
  // Define the suffix with the random string as a query parameter to avoid translation caching
  const suffix = `.json?v=${randomString}`;
  // Return a new instance of TranslateHttpLoader with the suffix
  return new TranslateHttpLoader(http, './assets/i18n/', suffix);
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
  ],
  exports: [TranslateModule],
  providers: [TranslateService],
})
export class AppTranslationModule {
  constructor(private translate: TranslateService) {
    let langs = AppConfig.langs;
    const clang = translate.currentLang;
    translate.addLangs(langs);
    // translate.setDefaultLang(clang);
    // translate.use(clang);
  }
}
