import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AfaqyValidation } from 'app/common/afaqy-validation';
import { CoreFormComponent } from 'app/common/core-form-compenent';
import {
  Unit,
  UnitCommand,
  UnitSensor,
  UnitServiceItem,
} from 'app/modules/units/models';
import { UnitGroupService, UnitService } from 'app/modules/units/services';
import { takeWhile } from 'rxjs/operators';
import { ApiRequestService, AuthService } from '../../../core/services';
import { ShipmentInputs } from '../../interfaces/interfaces';
import { UnitFormProfileComponent } from './profile/unit-form-profile.component';
import { UnitSettingsCopyComponent } from './unit-settings-copy/unit-settings-copy.component';
import * as moment from 'moment';
import { UnitFormCustomComponent } from 'app/shared/components/unit-form/custom-fields/unit-form-custom.component';
import { BehaviorSubject } from 'rxjs';
import { AfaqyHelper } from 'app/common';
import { CustomFieldsService } from 'app/modules/custom-fields/custom-fields.service';
import * as wjcCore from '@grapecity/wijmo';

@Component({
  selector: 'unit-form',
  templateUrl: './unit-form.component.html',
  styleUrls: ['./unit-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class UnitFormComponent extends CoreFormComponent implements OnInit {
  @ViewChild('customTab', { static: false, read: UnitFormCustomComponent })
  unitCustom: UnitFormCustomComponent;
  @ViewChild('UnitFormProfile') UnitFormProfile: any;
  @ViewChild('tabs') tabs: any;
  @ViewChild('stepperModel', { read: ViewContainerRef })
  stepperModel: ViewContainerRef;
  @ViewChild('UnitFormProfile', { static: false })
  unitFormProfileComponent: UnitFormProfileComponent;
  oldCounters = {};
  cctvApp = false;
  device_types = [
    {
      title: 'GPS',
      value: 'gps',
    },
    {
      title: 'Fixed Unit',
      value: 'fixed_unit',
    },
  ];

  showOnFixed = false;
  showOnGateway = false;
  random = 0;
  allowableDelay: number;
  simNumberValidators = [
    Validators.maxLength(16),
    Validators.pattern('^[+][0-9]*$'),
  ];
  simSerialValidators = [
    Validators.minLength(10),
    Validators.maxLength(25),
    Validators.pattern('^[0-9]+$'),
  ];
  hasRunningOperation = false;
  checkOperationValidation = false;
  customIds: Array<any> = [];
  customFieldsPermission: boolean;
  protected updateCustomInput: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    translate: TranslateService,
    route: ActivatedRoute,
    router: Router,
    fb: FormBuilder,
    service: UnitService,
    private authService: AuthService,
    public groupService: UnitGroupService,
    private unitService: UnitService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private apiRequest: ApiRequestService,
    public CustomFieldsService: CustomFieldsService
  ) {
    super(translate, route, router, fb, service);

    // this.simplify = 0; // override value from parent class // caused problems in other fields
    this.edit_title = 'edit';
    this.cid = 'units';
    this.filesPaths['icon'] = { url: '', upload: false };
    this.cctvApp = this.authService.hasApp('cctv');
    this.watchChanges();
  }

  get modelObject() {
    return new Unit();
  }

  get listURL(): string {
    const url: string = this.router.url;
    if (url.substr(1, 5) == 'units') {
      return '/units';
    } else if (url.substr(1, 8) == 'messages') {
      return '/messages';
    }
    // حسبى الله ونعم الوكيل
    // todo: need refactoring these conditions to return to url originated from
    else if (url.substr(1, 16) == 'second-by-second') {
      return '/second-by-second';
    } else if (url.substr(1, 8) == 'tracking') {
      return '/tracking';
    }
    return '/monitoring';
  }

  get formFiles() {
    return ['file'];
  }

  get shipmentFields(): ShipmentInputs {
    return {
      product_category: {
        initialValue: null,
        validators: [],
      },
      truck_type: {
        initialValue: null,
        validators: [],
      },
      shipment_type: {
        initialValue: null,
        validators: [],
      },
      shipment_payment_type: {
        initialValue: null,
        validators: [],
      },
      transporter_type: {
        initialValue: null,
        validators: [],
      },
      operating_area: {
        initialValue: null,
        validators: [],
      },
      weight_kilograms_per_day: {
        initialValue: null,
        validators: [
          Validators.min(1),
          Validators.max(100000),
          Validators.pattern('^[0-9]*'),
        ],
        initialValidators: [
          Validators.min(1),
          Validators.max(100000),
          Validators.pattern('^[0-9]*'),
        ],
      },
      kms_per_day: {
        initialValue: null,
        validators: [
          Validators.min(1),
          Validators.max(2000),
          Validators.pattern('^[0-9]*'),
        ],
        initialValidators: [
          Validators.min(1),
          Validators.max(2000),
          Validators.pattern('^[0-9]*'),
        ],
      },
      shipment_trip_drive_hrs_day: {
        initialValue: null,
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(24),
          Validators.pattern('^[0-9]*'),
        ],
        initialValidators: [
          Validators.min(1),
          Validators.max(24),
          Validators.pattern('^[0-9]*'),
        ],
      },
      allowable_delay_drive_hrs_day: {
        initialValue: null,
        validators: [
          Validators.min(1),
          Validators.pattern('^[0-9]*'),
          Validators.max(this.allowableDelay),
        ],
        initialValidators: [
          Validators.min(1),
          Validators.pattern('^[0-9]*'),
          Validators.max(this.allowableDelay),
        ],
      },
      expected_shipment_trip_day: {
        initialValue: null,
        validators: [
          Validators.min(1),
          Validators.max(1000),
          Validators.pattern('^[0-9]*'),
        ],
        initialValidators: [
          Validators.min(1),
          Validators.max(1000),
          Validators.pattern('^[0-9]*'),
        ],
      },
      car_pay_per_day: {
        initialValue: null,
        validators: [Validators.min(1), Validators.max(1000000)],
        initialValidators: [Validators.min(1), Validators.max(1000000)],
      },
      exceeding_temperature_limit_per_day: {
        initialValue: null,
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(1000),
          Validators.pattern('^[0-9]*'),
        ],
        initialValidators: [
          Validators.min(1),
          Validators.max(1000),
          Validators.pattern('^[0-9]*'),
        ],
      },
      temperature_upper_limit: {
        initialValue: null,
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(100),
        ],
        initialValidators: [Validators.min(1), Validators.max(100)],
      },
      exceeding_humidity_limit_per_day: {
        initialValue: null,
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(1000),
          Validators.pattern('^[0-9]*'),
        ],
        initialValidators: [
          Validators.min(1),
          Validators.max(1000),
          Validators.pattern('^[0-9]*'),
        ],
      },
      humidity_upper_limit: {
        initialValue: null,
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(100),
        ],
        initialValidators: [Validators.min(1), Validators.max(100)],
      },
    };
  }

  get form_fields(): any {
    const shipmentInputs = {};
    Object.keys(this.shipmentFields).forEach((key) => {
      shipmentInputs[key] = [
        null,
        this.shipmentFields[key]?.initialValidators
          ? this.shipmentFields[key]?.initialValidators
          : [],
      ];
    });

    const formFields = {
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(100),
        ],
      ],
      creator: [''],
      owner: [''],
      crat: [''],
      operation_code: [''],
      imei: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(25),
          Validators.pattern('^[a-zA-Z0-9]*$'),
        ],
      ],
      sim_number: [
        '+966',
        [
          Validators.required,
          // Validators.minLength(13),
          Validators.maxLength(16),
          Validators.pattern('^[+][0-9]*$'),
        ],
      ],
      sim_serial: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(25),
          Validators.pattern('^[0-9]+$'),
        ],
      ],
      skip_sim_information: [''],
      device: ['', [Validators.required]],
      device_serial: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(15),
          Validators.pattern('^[a-zA-Z0-9]*$'),
        ],
      ],
      device_password: [],
      is_wasl_connected: [''],
      device_type: ['gps', Validators.required],
      gateway: [false],
      device_gateway: [null],
      tailer_id: [],
      auto_tailer: [],
      driver_id: [],
      auto_driver: [],
      business_types: [
        {
          waste: false,
          transportation: false,
          water: false,
        },
      ],
      counters: this.fb.group({
        odometer_type: ['gps'],
        odometer: ['', AfaqyValidation.numberValidator],
        engine_hours_type: ['acc'],
        engine_hours: ['', AfaqyValidation.numberValidator],
      }),
      cctv: this.fb.group({
        dvr_id: ['', AfaqyValidation.numberValidatorIfexist],
        channels: [1],
      }),
      job_order: this.fb.group({
        number: [''],
        desc: [''],
        status_id: [''],
      }),
      profile: this.fb.group({
        vehicle_type: [''],
        measurement_type: ['count', [Validators.required]],
        max_capacity: [0, [AfaqyValidation.numberValidatorIfexist]],
        vin: [''],
        plate_number: [''],
        start_operation: [
          '',
          [
            AfaqyValidation.Dates_Y_M_ValidatorIfexist,
            Validators.minLength(7),
            Validators.maxLength(7),
          ],
        ],
        contract_milage: ['', AfaqyValidation.numberValidatorIfexist],
        opening_milage: ['', AfaqyValidation.numberValidatorIfexist],
        model: [''],
        fuel_type: [''],
        year: ['', AfaqyValidation.numberValidatorIfexist],
        seats: [
          2,
          [AfaqyValidation.numberValidatorIfexist, Validators.maxLength(2)],
        ],
        rfid: ['', Validators.maxLength(30)],
      }),
      fc: this.fb.group({
        rate: this.fb.group({
          enable: [true],
          summer_rate: [12],
          winter_rate: [10],
          winter_from_month: [12],
          winter_from_day: [1],
          winter_to_month: [3],
          winter_to_day: [1],
        }),
        time_of_ignoring_signals_after_stationary_on: [
          null,
          [Validators.min(0)],
        ],
        target_fuel_per_month: [null, [Validators.min(0)]],
        /*                math: this.fb.group({
                    enable: [false],
                    idling: [2],
                    urban: [10],
                    suburban: [7],
                    loaded_coef: [1.3],
                }),*/
      }),
      td: this.fb.group({
        move: ['acc+gps'],
        min_speed: [6, [AfaqyValidation.numberValidator, Validators.min(0)]],
        min_stop: [60, [AfaqyValidation.numberValidator, Validators.min(10)]],
        min_park: [300, [AfaqyValidation.numberValidator, Validators.min(0)]],
        min_sat: [4, [AfaqyValidation.numberValidator, Validators.min(1)]],
        max_distance: [
          10000,
          [AfaqyValidation.numberValidator, Validators.min(0)],
        ],
        min_trip_time: [
          60,
          [AfaqyValidation.numberValidator, Validators.min(30)],
        ],
        min_trip_distance: [
          100,
          [AfaqyValidation.numberValidator, Validators.min(100)],
        ],
      }),
      shipment_info: this.fb.group(shipmentInputs),
      custom_fields: this.fb.array([]),
      icon: [],
      groupsList: [],
    };
    if (!this.service.authService.checkPermissions('units-deviceAdmin')) {
      formFields.imei = [];
      formFields.device = [];
      formFields.device_password = [];
      formFields.device_serial = [];
      formFields.sim_number = ['+966'];
      formFields.sim_serial = [];
    }
    return formFields;
  }

  get DeviceType() {
    return this.form.get('device_type');
  }

  get DevicePassword() {
    return this.form.get('device_password');
  }

  get Gateway() {
    return this.form.get('gateway');
  }

  get SIMNumber() {
    return this.form.get('sim_number');
  }

  get SIMSerial() {
    return this.form.get('sim_serial');
  }

  get SkipSIM() {
    return this.form.get('skip_sim_information');
  }

  watchChanges() {
    this.DeviceType.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((value) => {
        // Set hide and show boolean for tabs
        this.showOnFixed = value === 'fixed_unit';
        if (this.showOnFixed && this.form.get('profile')) {
          this.form.get('profile').get('measurement_type').setValidators([]);
          this.form.get('profile').get('measurement_type').setErrors(null);
        } else {
          this.form
            .get('profile')
            .get('measurement_type')
            .setValidators([Validators.required]);
        }
        // Mandatory cases for SIM Fields
        this.form.updateValueAndValidity();
        this.validateSIMFields();

        this.checkRequiredState();
      });

    this.Gateway.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((value) => {
        // Mandatory cases for SIM Fields
        this.validateSIMFields();
        this.showOnGateway = !!value;
        this.checkRequiredState();
      });
  }

  refactorViewRespose(response): any {
    if (response?.data?.custom_fields) {
      response.data.custom_fields.map((custom) => {
        this.CustomFieldsService.translateCustomField(custom);
      });
    }

    /**
     * workaround different keys on description from backend,
     * if sent key simplify 1 other parts of form doesn't map and if true they work and desc get's unmapped
     * so refactoring response to fit needs on view
     */
    if (response?.data?.job_order?.d) {
      response.data.job_order.desc = response?.data?.job_order?.d;
    }
    return super.refactorViewRespose(response);
  }

  applyAfterLoad(response: any) {
    if (this.authService.isAdmin) {
      this.cctvApp = response['extra']['cctv'];
    }
  }

  loadComponent(component) {
    this.stepperModel.clear();
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = this.stepperModel.createComponent(componentFactory);
    componentRef.instance['item'] = this.object;
  }

  checkOperationProcess() {
    this.checkOperationValidation = true;
    this.hasRunningOperation = false;
    this.apiRequest.getRequest('general/check_running_operation', {}).subscribe(
      (res: any) => {
        this.checkOperationValidation = false;
        if (res?.data.hasOperation) {
          this.hasRunningOperation = true;
        } else {
          this.hasRunningOperation = false;
          this.navigateToUnitSettingsCopy();
        }
      },
      () => {
        this.checkOperationValidation = false;
      }
    );
  }

  navigateToUnitSettingsCopy() {
    this.loadComponent(UnitSettingsCopyComponent);
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.controls['td']['controls']['min_stop'].valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.updateParkMinValue();
      });
    this.formValueChangesSubscription();
    this.customFieldsPermission = this.checkCustomFieldsPermission();
  }

  checkCustomFieldsPermission() {
    return this.service.authService.checkPermissions('custom_fields-lists');
  }

  /**
   * set shipment custom validators dynamically from reference base defined on this.shipmentFields
   * @param inputName
   */
  setShipmentInputValidators(inputName: string) {
    this.form.controls['shipment_info']['controls'][inputName].setValidators(
      this.shipmentFields[inputName].validators
    );
    this.form.controls['shipment_info']['controls'][inputName].markAsDirty();
    this.form.controls['shipment_info']['controls'][
      inputName
    ].updateValueAndValidity({ onlySelf: true });
  }

  /**
   * clear shipment custom validators dynamically
   * @param inputName
   */
  clearShipmentInputValidators(inputName: string) {
    this.form.controls['shipment_info']['controls'][
      inputName
    ].clearValidators();
    this.form.controls['shipment_info']['controls'][inputName].markAsPristine();
    this.form.controls['shipment_info']['controls'][
      inputName
    ].updateValueAndValidity({ onlySelf: true });
  }

  /**
   * form value subscription logic
   * added here logic related to set and clear validators based on business requirements
   */
  formValueChangesSubscription() {
    this.form.controls['shipment_info'].valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((value) => {
        // Humidity Validations
        if (
          (value?.exceeding_humidity_limit_per_day !== undefined &&
            value?.exceeding_humidity_limit_per_day !== null) ||
          (value?.humidity_upper_limit !== undefined &&
            value?.humidity_upper_limit !== null)
        ) {
          this.setShipmentInputValidators('exceeding_humidity_limit_per_day');
          this.setShipmentInputValidators('humidity_upper_limit');
        } else {
          this.clearShipmentInputValidators('exceeding_humidity_limit_per_day');
          this.clearShipmentInputValidators('humidity_upper_limit');
        }

        // Temperature Validations
        if (
          (value?.exceeding_temperature_limit_per_day !== undefined &&
            value?.exceeding_temperature_limit_per_day !== null) ||
          (value?.temperature_upper_limit !== undefined &&
            value?.temperature_upper_limit !== null)
        ) {
          this.setShipmentInputValidators(
            'exceeding_temperature_limit_per_day'
          );
          this.setShipmentInputValidators('temperature_upper_limit');
        } else {
          this.clearShipmentInputValidators(
            'exceeding_temperature_limit_per_day'
          );
          this.clearShipmentInputValidators('temperature_upper_limit');
        }
        if (
          (value?.allowable_delay_drive_hrs_day !== undefined &&
            value?.allowable_delay_drive_hrs_day !== null) ||
          (value?.shipment_trip_drive_hrs_day !== undefined &&
            value?.shipment_trip_drive_hrs_day !== null)
        ) {
          this.setShipmentInputValidators('shipment_trip_drive_hrs_day');
          this.allowableDelay =
            24 -
            (value?.shipment_trip_drive_hrs_day
              ? value?.shipment_trip_drive_hrs_day
              : 0);
          this.setShipmentInputValidators('allowable_delay_drive_hrs_day');
        } else {
          this.clearShipmentInputValidators('shipment_trip_drive_hrs_day');
          this.clearShipmentInputValidators('allowable_delay_drive_hrs_day');
        }
      });
  }

  ngAfterViewInit() {
    if (this.tabs) {
      let tabs: any[] = [];
      tabs = this.tabs.nativeElement['children'][0]['children'][0]['children'];
      for (let index = 0; index < tabs.length; index++) {
        const element = tabs[index];
        element.setAttribute('testid', element.innerText + '-tab');
      }
    }
  }

  prepareValidationMessages(messagesList: any) {
    // Object.keys(messagesList).forEach((tab: any) => {
    // const assignationErrors = {};
    //     Object.keys(messagesList[tab]).forEach((key: any) => {
    //         let index = 0

    //         for (const [keyPair, value] of Object.entries(
    //             messagesList[tab][key]
    //         )) {
    //             assignationErrors[tab + "." + keyPair + "." + index] = value;
    //         }
    //         index += 1;

    //     });
    //     delete messagesList[tab];
    //     messagesList = { ...messagesList, ...assignationErrors };
    // })
    return messagesList;
  }

  updateParkMinValue() {
    const minstopVal =
      this.form.controls['td']['controls']['min_stop'].value + 1;
    this.form.controls['td']['controls']['min_park'].setValidators([
      AfaqyValidation.numberValidator,
      Validators.min(minstopVal),
    ]);
  }

  applySideEffects() {
    super.applySideEffects();
    const urlAction = this.getUrlAction();
    if (urlAction === 'copy') {
      for (const key of [
        'imei',
        'device',
        'device_password',
        'device_serial',
        'sim_serial',
      ]) {
        this.object[key] = '';
        this.form.controls[key].setValue('');
      }
      this.form.controls['sim_number'].setValue('+966');
      if (this.object.services.length) {
        this.object.services.map((item) => {
          item['id'] = '';
          item['unit_id'] = '';
          return item;
        });
      }
      if (this.object.sensors.length) {
        this.object.sensors.map((item) => {
          item['id'] = '';
          item['unit_id'] = '';
          return item;
        });
      }
      if (this.object.commands.length) {
        this.object.commands.map((item) => {
          item['id'] = '';
          item['unit_id'] = '';
          return item;
        });
      }
      if (this.object.driver_behavior.length) {
        this.object.driver_behavior.map((item) => {
          item['id'] = '';
          item['unit_id'] = '';
          return item;
        });
      }
    }
    if (this.object.custom_fields.length) {
      this.customIds = [];
      this.object.custom_fields.forEach((item) => {
        this.customIds.push(item.id);
      });
      // this.unitCustom.custom.setValue(this.customIds);
      // this.form.setControl('custom_fields', this.fb.array(customFGs));
    }
    this.filesPaths['icon'] = { url: this.object.iconUrl, upload: false };
    this.removeInputValue('icon');
    //Round the values to 2 digits
    const odometer =
      Math.round(
        parseFloat(
          this.object.counters.odometer ? this.object.counters.odometer : 0
        ) * 100
      ) / 100;
    const engineHours =
      Math.round(
        (parseFloat(
          this.object.counters.engine_hours
            ? this.object.counters.engine_hours
            : 0
        ) /
          3600) *
          100
      ) / 100;
    this.form.controls['counters']['controls'].odometer.setValue(odometer);
    this.form.controls['counters']['controls'].engine_hours.setValue(
      engineHours
    );
    this.oldCounters = this.object.counters;
    this.updateParkMinValue();
    setTimeout(() => {
      this.form.controls['skip_sim_information'].updateValueAndValidity();
    }, 1000);
  }

  refreshRandom() {
    this.random = Math.random();
  }

  reset() {
    super.reset();
    if (!this.object.id) {
      this.form.controls['sim_number'].setValue('+966');
      this.form.controls['business_types'].setValue({
        waste: false,
        transportation: false,
        water: false,
        taxi: false,
        hajj: false,
      });
      const business_types = this.getBusinessTypesFromObject({
        waste: false,
        transportation: false,
        water: false,
        taxi: false,
        hajj: false,
      });

      this.unitFormProfileComponent.businessTypes = new wjcCore.CollectionView(
        business_types
      );
      this.unitFormProfileComponent.businessTypeChange(
        this.unitFormProfileComponent.businessTypes,
        false
      );
      this.unitCustom.custom.setValue([]);
      (this.form.controls['custom_fields'] as FormArray).clear();
    }
    if (this.object.id) {
      const customFieldsObject = this.object.custom_fields;
      this.unitCustom.custom.setValue(customFieldsObject);
      (this.form.controls['custom_fields'] as FormArray).clear();
      this.unitCustom.custom.patchValue(this.customIds);
    }
    if (this.unitFormProfileComponent) {
      this.unitFormProfileComponent.resetUnitProfileDefaultValues();
      if (this.object.id) {
        const business_types = this.getBusinessTypesFromObject(
          this.object.business_types
        );
        this.unitFormProfileComponent.businessTypes =
          new wjcCore.CollectionView([...business_types]);
        this.unitFormProfileComponent.businessTypeChange(business_types, false);
      }
    }
    this.refreshRandom();
  }

  getBusinessTypesFromObject(businessTypesObject: any): any[] {
    return [
      {
        id: 'waste',
        title: this.translate.instant('units.waste'),
        checked: !!businessTypesObject.waste,
      },
      {
        id: 'transportation',
        title: this.translate.instant('units.transportation'),
        checked: !!businessTypesObject.transportation,
      },
      {
        id: 'water',
        title: this.translate.instant('units.water'),
        checked: !!businessTypesObject.water,
      },
      {
        id: 'hajj',
        title: this.translate.instant('units.hajj'),
        checked: !!businessTypesObject.hajj,
      },
      {
        id: 'taxi',
        title: this.translate.instant('units.taxi'),
        checked: !!businessTypesObject.taxi,
      },
    ];
  }

  deleteIcon(event: any) {
    this.deleteFile('icon_img', 'icon');
  }

  changeIcon(event: any) {
    this.fileChange(event, 'icon_img', 'icon');
  }

  selectIcon(event: any) {
    this.filesPaths['icon'] = { url: event.url, upload: false };
    this.selectedFileNames['icon_img'] = '';
    delete this.files['icon_img'];
  }

  updateResourcesAfterSave(id: any, add: boolean = false) {
    super.updateResourcesAfterSave(id, add);
    this.unitService.updateDrivers(id, this.object.driver_id);
    this.unitService.updateTailers(id, this.object.tailer_id);
    if (this.isAdd) {
      this.service.addToResource(id);
    }
    this.unitService?.updateCustomFields(this.editObject, this.object);
  }

  afterPrepareSave(obj: any) {
    if (!this.isAdd) {
      const current = this.service.getItemFromResources(this.getResourceID());
      if (obj.counters.engine_hours == this.oldCounters['engine_hours']) {
        obj.counters.engine_hours = current.counters.engine_hours;
      }
      if (obj.counters.odometer == this.oldCounters['odometer']) {
        obj.counters.odometer = current.counters.odometer;
      }
    }

    if (this.isAdd) {
      obj['services'] = [];
      this.object.services.forEach((item) => {
        const service = new UnitServiceItem();
        service.copyInto(item);
        obj['services'].push(service.getCreatePostData());
      });
      obj['sensors'] = [];
      this.object.sensors.forEach((item) => {
        const sensor = new UnitSensor();
        sensor.copyInto(item);
        obj['sensors'].push(sensor.getCreatePostData());
      });
      obj['commands'] = [];
      this.object.commands.forEach((item) => {
        const command = new UnitCommand();
        command.copyInto(item);
        obj['commands'].push(command.getCreatePostData());
      });
    }
    if (obj.driver_id === null) {
      obj.driver_id = '';
    }
    if (obj.tailer_id === null) {
      obj.tailer_id = '';
    }
    if (obj.status_id === null) {
      obj.status_id = '';
    }
    if (obj.sim_number === '+966') {
      obj.sim_number = '';
    }
    obj.counters.engine_hours *= 3600;
    return obj;
  }

  isMainTabInvalid(): boolean {
    return (
      this.form.get('device_type').invalid ||
      this.form.get('gateway').invalid ||
      this.form.get('name').invalid ||
      this.form.get('operation_code').invalid ||
      this.form.get('device').invalid ||
      this.form.get('device_password').invalid ||
      this.form.get('imei').invalid ||
      this.form.get('device_serial').invalid ||
      this.form.get('device_gateway').invalid ||
      this.form.get('sim_number').invalid ||
      this.form.get('sim_serial').invalid ||
      this.form.controls['counters']['controls']['odometer_type'].invalid ||
      this.form.controls['counters']['controls']['odometer'].invalid ||
      this.form.controls['counters']['controls']['engine_hours_type'].invalid ||
      this.form.controls['counters']['controls']['engine_hours'].invalid ||
      this.form.get('creator').invalid ||
      this.form.get('crat').invalid ||
      this.form.get('owner').invalid ||
      this.form.get('skip_sim_information').invalid
    );
  }

  private checkRequiredState() {
    this.DevicePassword.setValidators([]);
    if (this.DeviceType.value === 'fixed_unit' && !this.Gateway.value) {
      this.DevicePassword.setValidators([Validators.required]);
    }
    this.DevicePassword.updateValueAndValidity();
  }

  private validateSIMFields() {
    // if user has no permission, return and don't updateValueAndValidity as it fires field errors
    if (!this.authService.checkPermissions('units-deviceAdmin', '')) {
      return;
    }
  }

  fillFormValues() {
    let fobj = {};
    for (let field in this.form_fields) {
      fobj[field] = this.object[field];
      if (field === 'shipment_info') {
        Object.keys(fobj[field]).forEach((key) => {
          if (fobj[field][key] === '' || fobj[field][key] === '') {
            fobj[field][key] = null;
          }
        });
      }
    }
    this.form.reset(fobj);
    this.applySideEffects();
  }
  refactorObjectBeforeSubmit(obj) {
    if (obj.device_type === 'fixed_unit') {
      delete obj.commands;
      delete obj._commands;
      delete obj.custom_fields;
      delete obj._custom_fields;
    }
    return obj;
  }

  onSubmit(event?) {
    if (!this.isAdd && !this.auth.permissions.includes('units-deviceAdmin')) {
      this.form.get('sim_serial').clearValidators();
      this.form.get('sim_serial').updateValueAndValidity();
      this.form.get('sim_number').clearValidators();
      this.form.get('sim_number').updateValueAndValidity();
    }
    this.form.value.custom_fields = this.form
      .get('custom_fields')
      .getRawValue()
      .map((item) => {
        return this.customFieldRefactor(item);
      });
    if (this.isAdd) {
      this.form.value.sensors = this.form?.value?.sensors?.map((x) => {
        x.id = null;
        return x;
      });
    }
    super.onSubmit();
  }

  customFieldRefactor(item: any) {
    if (item.type == 'date') {
      item.value = moment(item.value)
        .tz(this.auth.user.timezone)
        .utc(true)
        .format('YYYY-MM-DD');
    }
    delete item.typeEn;
    delete item.typeAr;
    return item;
  }
}
