<div id="particles-js"></div>
<div
  class="main-container w-100 h-100 d-flex justify-content-center align-items-center"
>
  <div class="card p-5">
    <div class="card-body">
      <div class="logo text-center">
        <img
          class="img-fluid brand-image"
          style="max-height: 100px"
          src="{{ configs.loginlogo }}"
          alt="{{ configs.title }}"
        />
      </div>
      <div class="text-center py-5">
        <h4>{{ 'common.loggedOutSession' | translate }}</h4>
      </div>
    </div>
  </div>
</div>
