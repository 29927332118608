import { AppModel } from '../../../common/classes';
import { AfaqyObject } from 'app/common';

export class UnitSensorColor extends AfaqyObject {
  private _from: number;
  private _to: number;
  private _type: any;
  private _color: string;

  protected copyFields = ['from', 'to', 'type', 'color'];

  get from(): number {
    return this._from;
  }

  set from(value: number) {
    this._from = value;
  }

  get to(): number {
    return this._to;
  }

  set to(value: number) {
    this._to = value;
  }

  get type(): any {
    return this._type;
  }

  set type(value: any) {
    this._type = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }
}

export class UnitSensor extends AppModel {
  private _unit_id: string;
  private _name: string;
  private _type: string;
  private _param: string;
  private _result_type: string;
  private _text_0: string;
  private _text_1: string;
  private _units: string;
  private _low_val: string;
  private _high_val: string;
  private _formula: string;
  private _formula_value: number;
  private _calibration: number[];
  private _icon: string;
  private _tooltip_show: boolean;
  private _is_vss_default: boolean = false;
  private _last_update: boolean;
  private _fixed: boolean;
  private _colors: UnitSensorColor[] = [];
  private _codeValue: number[];

  protected copyFields = [
    'id',
    'name',
    'unit_id',
    'type',
    'param',
    'result_type',
    'text_0',
    'text_1',
    'units',
    'low_val',
    'high_val',
    'formula',
    'formula_value',
    'calibration',
    'icon',
    'tooltip_show',
    'is_vss_default',
    'last_update',
    'fixed',
    'colors',
    'codeValue',
  ];
  protected createFields = [
    'name',
    'unit_id',
    'user_id',
    'type',
    'param',
    'result_type',
    'text_0',
    'text_1',
    'units',
    'low_val',
    'high_val',
    'formula',
    'formula_value',
    'calibration',
    'icon',
    'tooltip_show',
    'is_vss_default',
    'last_update',
    'fixed',
    'colors',
    'codeValue',
  ];
  protected updateFields = [
    'name',
    'unit_id',
    'user_id',
    'type',
    'param',
    'result_type',
    'text_0',
    'text_1',
    'units',
    'low_val',
    'high_val',
    'formula',
    'formula_value',
    'calibration',
    'icon',
    'tooltip_show',
    'is_vss_default',
    'last_update',
    'fixed',
    'colors',
    'codeValue',
  ];

  get unit_id(): string {
    return this._unit_id;
  }

  set unit_id(value: string) {
    this._unit_id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get param(): string {
    return this._param;
  }

  set param(value: string) {
    this._param = value;
  }

  get result_type(): string {
    return this._result_type;
  }

  set result_type(value: string) {
    this._result_type = value;
  }

  get text_0(): string {
    return this._text_0;
  }

  set text_0(value: string) {
    this._text_0 = value;
  }

  get text_1(): string {
    return this._text_1;
  }

  set text_1(value: string) {
    this._text_1 = value;
  }

  get units(): string {
    return this._units;
  }

  set units(value: string) {
    this._units = value;
  }

  get low_val(): string {
    return this._low_val;
  }

  set low_val(value: string) {
    this._low_val = value;
  }

  get high_val(): string {
    return this._high_val;
  }

  set high_val(value: string) {
    this._high_val = value;
  }

  get formula(): string {
    return this._formula;
  }

  set formula(value: string) {
    this._formula = value;
  }

  get formula_value(): number {
    return this._formula_value;
  }

  set formula_value(value: number) {
    this._formula_value = value;
  }

  get calibration(): number[] {
    return this._calibration;
  }

  set calibration(value: number[]) {
    this._calibration = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get tooltip_show(): boolean {
    return this._tooltip_show;
  }

  set tooltip_show(value: boolean) {
    this._tooltip_show = String(value) === '' ? true : value;
  }

  get is_vss_default(): boolean {
    return this._is_vss_default;
  }

  set is_vss_default(value: boolean) {
    this._is_vss_default = value;
  }

  get last_update(): boolean {
    return this._last_update;
  }

  set last_update(value: boolean) {
    this._last_update = value;
  }

  get fixed(): boolean {
    return this._fixed;
  }

  set fixed(value: boolean) {
    this._fixed = value;
  }

  get colors(): UnitSensorColor[] {
    return this._colors;
  }

  set colors(value: UnitSensorColor[]) {
    this._colors = value;
  }

  get codeValue(): number[] {
    return this._codeValue;
  }

  set codeValue(value: number[]) {
    this._codeValue = value;
  }
}
