<div class="form-assignation-form p-relative">
  <div class="padddig-y" *ngIf="!loading">
    <div class="search-ctrl-label">
      <input
        type="text"
        class="form-control form-control-sm"
        (keyup)="setSelectedSearchText($event.target.value)"
        [attr.testId]="'assignation_search'"
      />
    </div>
  </div>
  <div class="loader-container" *ngIf="loading">
    <afaqy-spinner [loading]="loading"></afaqy-spinner>
  </div>
  <wj-flex-grid
    *ngIf="!loading"
    [attr.testId]="'assignation_data'"
    class="custom-flex-grid"
    #grid
    [itemsSource]="cvData"
    [childItemsPath]="'subusers'"
    [selectionMode]="'None'"
    [isReadOnly]="true"
    [headersVisibility]="'None'"
  >
    <wj-flex-grid-column
      [allowSorting]="false"
      binding="username"
      [isReadOnly]="true"
      [width]="'*'"
    ></wj-flex-grid-column>
    <wj-flex-grid-column
      [allowSorting]="false"
      binding="email"
      [isReadOnly]="true"
      [width]="'*'"
    ></wj-flex-grid-column>
    <wj-flex-grid-column
      [allowSorting]="false"
      header=""
      binding="is_old_selected"
      [width]="50"
    >
      <ng-template
        wjFlexGridCellTemplate
        [cellType]="'Cell'"
        cellClass="class"
        let-cell="cell"
        let-item="item"
      >
        <div>
          <div class="inner-wijmo-cell">
            <div class="wijmo-checkbox">
              <div class="checkbox-inline">
                <div class="checkbox-squared center">
                  <label [for]="'module.' + item.id" class="switch">
                    <input
                      [disabled]="isView"
                      [attr.testId]="item.id"
                      [id]="'module.' + item.id"
                      type="checkbox"
                      [checked]="selected_ids[item.id]"
                      (click)="
                        toggleSelection(item.id, $event.target.checked, $event)
                      "
                      [attr.testId]="'checkbox_squared'"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </wj-flex-grid-column>
  </wj-flex-grid>
</div>
