import { AfaqyObject } from '../../../common/classes';
export class UnitProfile extends AfaqyObject {
  private _vehicle_type: string = '';
  private _measurement_type: string = 'count';
  private _max_capacity: number = 0;
  private _vin: string = '';
  private _plate_number: string = '';
  private _model: string = '';
  private _year: number;
  private _seats: number = 2;
  private _fuel_type: string = '';
  private _job_order: string;
  private _status_description: string;
  private _start_operation: string;
  private _contract_milage: string;
  private _opening_milage: string;
  private _rfid: string = '';

  protected copyFields = [
    'vehicle_type',
    'measurement_type',
    'max_capacity',
    'vin',
    'plate_number',
    'model',
    'year',
    'seats',
    'fuel_type',
    'start_operation',
    'contract_milage',
    'opening_milage',
    'job_order',
    'status_description',
    'rfid',
  ];

  get measurement_type(): string {
    return this._measurement_type;
  }

  set measurement_type(value: string) {
    this._measurement_type = value;
  }

  get max_capacity(): number {
    return this._max_capacity;
  }

  set max_capacity(value: number) {
    this._max_capacity = value;
  }

  get status_description(): string {
    return this._status_description;
  }

  set status_description(value: string) {
    this._status_description = value;
  }

  get job_order(): string {
    return this._job_order;
  }

  set job_order(value: string) {
    this._job_order = value;
  }

  get vehicle_type(): string {
    return this._vehicle_type;
  }

  set vehicle_type(value: string) {
    this._vehicle_type = value;
  }

  get vin(): string {
    return this._vin;
  }

  set vin(value: string) {
    this._vin = value;
  }

  get plate_number(): string {
    return this._plate_number;
  }

  set plate_number(value: string) {
    this._plate_number = value;
  }

  get model(): string {
    return this._model;
  }

  set model(value: string) {
    this._model = value;
  }

  get year(): number {
    return this._year;
  }

  set year(value: number) {
    this._year = value;
  }

  get seats(): number {
    return this._seats;
  }

  set seats(value: number) {
    this._seats = value;
  }

  get fuel_type(): string {
    return this._fuel_type;
  }

  set fuel_type(value: string) {
    this._fuel_type = value;
  }

  get start_operation(): string {
    return this._start_operation;
  }

  set start_operation(value: string) {
    this._start_operation = value;
  }

  get contract_milage(): string {
    return this._contract_milage;
  }

  set contract_milage(value: string) {
    this._contract_milage = value;
  }

  get opening_milage(): string {
    return this._opening_milage;
  }

  set opening_milage(value: string) {
    this._opening_milage = value;
  }
  get rfid(): string {
    return this._rfid;
  }

  set rfid(value: string) {
    this._rfid = value;
  }
}
