<div *ngIf="message && message.message">
  <alert
    [type]="message.type"
    [dismissible]="message.dismissible"
    [dismissOnTimeout]="message.timeout"
    (onClosed)="onClosed($event)"
  >
    <span>{{ langKey + message.message | translate }}</span>
    <span
      [popper]="tooltipcontent"
      [popperTrigger]="'hover'"
      [popperPlacement]="'auto-end'"
      [popperApplyClass]="'msg-container'"
      class="ml-5"
      *ngIf="messageList.length"
    >
      <i class="fa fa-exclamation-circle"></i>
    </span>
  </alert>
  <popper-content #tooltipcontent>
    <div *ngFor="let messages of messageList">
      <p [style.direction]="translate.currentLang == 'ar' ? 'rtl' : 'ltr'">
        <strong>{{ messages.msg.key }}</strong>
      </p>
      <p *ngFor="let value of messages.msg.value">
        {{ 'validations.' + value | translate }}
      </p>
      <hr />
    </div>
  </popper-content>
</div>
